var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"transparent",attrs:{"loading":_vm.isLoading,"headers":_vm.headers,"items":_vm.data,"item-key":'id',"mobile-breakpoint":0,"loading-text":"Loading... Please wait","disable-pagination":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"header.progress",fn:function(ref){
var header = ref.header;
return [(_vm.sortDesc===false)?_c('th',{staticStyle:{"cursor":"pointer"},on:{"click":_vm.sortCalories}},[_vm._v(_vm._s(header.text)+" "),_c('v-icon',{attrs:{"small":""}},[_vm._v("ri-arrow-up-line")])],1):_c('th',{staticStyle:{"cursor":"pointer"},on:{"click":_vm.sortCalories}},[_vm._v(_vm._s(header.text)+" "),_c('v-icon',{attrs:{"small":""}},[_vm._v("ri-arrow-down-line")])],1)]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('tr',{staticClass:"flat-b-1 rounded-lg"},[_c('td',{staticClass:"white text-capitalize rounded-l-lg",domProps:{"textContent":_vm._s(item.voucher_code)}}),_c('td',{staticClass:"white text-capitalize"},[_vm._v(" "+_vm._s(item.pmo_code))]),_c('td',{staticClass:"white text-capitalize text-center font-weight-bold"},[_vm._v(_vm._s(item.sequence))]),_c('td',{staticClass:"white text-capitalize text-center font-weight-bold"},[(item.status_callback_url == null)?_c('div',[_vm._v("-")]):(item.status_callback_url)?_c('v-chip',{attrs:{"color":"success","small":"","outlined":""}},[_vm._v("true")]):_c('v-chip',{attrs:{"color":"error","small":"","outlined":""}},[_vm._v("false")])],1),_c('td',{staticClass:"white text-capitalize",staticStyle:{"width":"20%"}},[_vm._v(_vm._s(item.member.fullname))]),_c('td',{staticClass:"white text-capitalize"},[_vm._v(_vm._s(item.member.phone))]),_c('td',{staticClass:"white text-capitalize text-center"},[_vm._v(_vm._s(_vm.convertCreatedAt(item.created_at)))]),_c('td',{staticClass:"white rounded-r-lg",staticStyle:{"width":"5%"}},[_c('div',{staticClass:"d-flex flex-row"},[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"title":"more","icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("ri-more-line")])],1)]}}],null,true)},[_c('v-list',[(item.status_callback_url == false)?_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){return _vm.resetState(item)}}},[_c('v-list-item-title',[_vm._v("Reset State")])],1):_vm._e(),_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){return _vm.copyToClipboard(item.state)}}},[_c('v-list-item-title',[_vm._v("Copy Link State")])],1),_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){return _vm.$emit('detail', item)}}},[_c('v-list-item-title',[_vm._v("Detail")])],1)],1)],1)],1)])])]}}])}),_c('v-snackbar',{attrs:{"top":"","color":"primary","outlined":"","timeout":_vm.snackbar.timeout},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"small":"","icon":"","color":"error"},on:{"click":function($event){_vm.snackbar.state = false}}},'v-btn',attrs,false),[_c('v-icon',[_vm._v("$close")])],1)]}}]),model:{value:(_vm.snackbar.state),callback:function ($$v) {_vm.$set(_vm.snackbar, "state", $$v)},expression:"snackbar.state"}},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.snackbar.text)}})])],1)}
var staticRenderFns = []

export { render, staticRenderFns }