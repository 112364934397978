<template>
  <div class="white pa-4 rounded-lg">
    <form ref="form">
      <v-row>
        <v-col id="search" cols="12">
          <div class="mb-2">Title</div>
          <v-text-field  
            placeholder="Title" 
            outlined
            persistent-hint
            color="primary"
            v-model="title">
          </v-text-field>        
        </v-col>
        <v-col cols="12">
          <div class="mb-2">Konten</div>
          <editor api-key="4paqvfu388didkn8yr5v662i655cn9x3g5w1usf7ucmypuoq" v-model="content" placeholder="Masukan privacy policy"
            :init="{
              height: 350,
              menubar: false,
              plugins: [
                'advlist autolink lists link image charmap print preview anchor',
                'searchreplace visualblocks code fullscreen',
                'insertdatetime media table paste code help wordcount'
              ],
              toolbar:
                'undo redo | formatselect | fontsize | bold italic | forecolor backcolor | alignleft aligncenter alignright alignjustify | outdent indent | bullist numlist | image link table | fullscreen',
              image_uploadtab: false,
              relative_urls : false,
              remove_script_host : false,
              convert_urls : true,
              content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px;background:#f4f6f9; }'
            }" />
        </v-col>
      </v-row>

      <v-card-actions class="pb-2 px-7">
        <v-spacer></v-spacer>
        <v-btn 
          width="100"
          elevation="0"
          color="primary"
          class="text-capitalize white--text"
          :loading="process.run"
          :disabled="process.run"
          @click="save()">
          Simpan
        </v-btn>
      </v-card-actions>
    </form>

    <v-snackbar :color="snackbar.color" top outlined v-model="snackbar.state" :timeout="5000">
      <div v-html="snackbar.text" class="text-capitalize"></div>
      <template v-slot:action="{ attrs }">
        <v-btn
          small
          icon
          :color="snackbar.color"
          v-bind="attrs"
          @click="snackbar.state = false">
          <v-icon>$close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import Editor from '@tinymce/tinymce-vue'
export default {
  components: {
    Editor
  },
  data: () => ({
    title: "",
    content: "",
    slug:"",
    is_status: false,
    detail_title: "",
    detail_company: {},
    process: {
      run: false,
    },
    snackbar: {
      state: false,
      text: "",
      color: "primary"
    }
  }),
  created() {
    this.detailCompany()
    this.detailModalSiapKerja()
  },
  methods: {
    async save() {
      this.process.run = true
      const data = {
        title: this.title,
        content: this.content,
        slug: this.slug,
        is_status: this.is_status,
        company_id: this.detail_company.id
      }
      this.axios.post('/company/v1/create-or-update-page', data)
        .then(response => {
          let res = response.data
          this.snackbar = {
            state: true,
            text: res.message,
            color: 'primary'
          }
          this.process.run = false
        })
        .catch(error => {
          let err = error.response.data
          this.snackbar = {
            state: true,
            text: err.message,
            color: 'error'
          }
          this.process.run = false
        })
    },
    detailModalSiapKerja() {
      this.axios.get('/company/v1/detail-page/modal-siap-kerja')
        .then(response => {
          let res = response.data
          this.detail_title = res.data.title
          this.title = res.data.title
          this.slug = res.data.slug
          this.content = res.data.content
          this.is_status = res.data.is_status
        })
        .catch(error => {
          console.log(error)
        })
    },
    detailCompany() {
      this.axios.get('/company/v1/detail')
        .then(response => {
          let res = response.data.data
          this.detail_company = res
        })
        .catch(error => {
          console.log(error)
        })
    }
  }
}
</script>