<template>
  <div
    class="drop display-inline text-center"
    @dragover.prevent
    @drop="onDrop2"
  >
    <input
      hidden
      ref="file"
      type="file"
      @change="onChange"
      accept="video/mp4"
    />

    <!-- {{ data }} -->
    <div v-if="data && data.url" class="fill-height pa-4">
      <div  v-if="!process.upload">
        <video :src="data.url" width="300" height="240" controls></video>
        <div class="text-right">
          <v-btn small fab color="white" @click="$refs.file.click()">
            <v-icon dark> ri-arrow-go-back-line </v-icon>
          </v-btn>
          <v-btn small fab color="white" @click="deleteFile()">
            <v-icon dark> ri-delete-bin-7-line </v-icon>
          </v-btn>
        </div>
      </div>
      <div class="mb-2 pt-8 px-7" v-if="process.upload">
        <v-progress-linear
          indeterminate
          color="primary">
        </v-progress-linear>
        <span>
          Uploading File . . .
        </span>
      </div>
    </div>
    <div
      v-else
      class="hidden display-inline align-center py-4"
      v-bind:class="{ image2: true }"
    >
      <div v-if="!disabledForm && !process.upload">
        <p class="mb-2 pt-8">
          <v-icon large>ri-video-fill</v-icon>
        </p>
        <!-- <p class="mb-0 subtitle-2">Tarik dan letakan video di sini</p>
        <p class="mb-2 subtitle-2">atau</p> -->
        <p class="mb-2 subtitle-2">Silahkan upload video</p>
        <label class="btn-img display-inline text-center">
          Pilih Video
          <input
            type="file"
            name="image2"
            @change="onChange2"
            accept="video/mp4"
          />
        </label>
      </div>
      <div class="mb-2 pt-8 px-7" v-if="process.upload">
        <v-progress-linear
          indeterminate
          color="primary">
        </v-progress-linear>
        <span>
          Uploading File . . .
        </span>
      </div>
      <div v-if="disabledForm && !process.upload">
        <p class="mb-2 pt-8">
          <v-icon large>ri-video-fill</v-icon>
        </p>
        <p class="mb-0 subtitle-2">Silahkan upload video sebelumnya </p>
      </div>
    </div>
  </div>
</template>

<script>
  import model from "../_mixins/model";
  export default {
    name: "VideoInput",
    props: {
      disabledForm: {
        type: Boolean,
        default: false
      },
      resolution: {
        type: String,
        default: ""
      },
      indexFile: {
        type: Number,
        default: 0
      },
    },
    mixins: [model],
    data: () => ({
      process: {
        upload: false,
      },
    }),
    methods : {
      deleteFile() {
        this.$set(this.data, "url", "");
        // this.$emit("delete_file", this.indexFile);
      },
      onDrop2: function (e) {
        e.stopPropagation();
        e.preventDefault();
        var files = e.dataTransfer.files;
        this.createFile(files[0]);
      },
      onChange() {
        // set fiel_variants [{label: "", url: ""}]

        this.uploadCover2(this.$refs.file.files[0]);
        // this.uploadCover2(this.data.file);
        // this.$set(this.data, "file", this.$refs.file.files[0]);
        // this.$set(this.data.file, "src", URL.createObjectURL(this.data.file));
      },
      onChange2(e) {
        var files = e.target.files[0];
        this.image2 = URL.createObjectURL(files);

        this.uploadCover2(files);
        // this.$set(this.data, "file", e.target.files[0]);
        // this.createFile(files[0]);
        // this.uploadCover(files[0]);
      },
      async uploadCover2(file) {
        this.process.upload = true;
        var formData = new FormData();
        // let a = file;
        // console.log("img", a);

        formData.append("file", file, "video.mp4");
        formData.append("source", "upload");
        formData.append("title", "file_variants");

        // console.log("result", formData);

        await this.$store
          .dispatch("media/create", formData)
          .then((res) => {
            if (res.status == 200) {
              
              console.log('result', res.data)
              this.$set(this.data, "label", this.resolution);
              this.$set(this.data, "url", res.data.public_url);
              console.log('result', this.data)

              this.$emit("input_file", {
                index: this.indexFile,
                data: this.data,
              });

              this.process.upload = false;
            }
          })
          .catch(() => {
            this.process.upload = false;
            // console.log("error", error);
          });
      },
    }
  }
</script>