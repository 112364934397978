<template>
  <v-row justify="center">
    <v-col v-if="!isTrainer" cols="4">
      <v-row class="flex-column" align-content="center">
        <v-col>
          <v-img src="@/assets/images/data_empty.png" />
        </v-col>
        <v-col>
          <div class="title text-center">Tidak ada trainer</div>
        </v-col>
        <div class="subtitle body-1 text-center">
          klik tombol di bawah ini untuk menambahkan Trainer baru
        </div>
        <v-col class="text-center">
          <v-btn color="primary" elevation="0" @click="addTrainer">
            <v-icon>ri-add-line</v-icon> Trainer baru
          </v-btn>
        </v-col>
      </v-row>
    </v-col>

    <v-col v-else>

      <v-row align="center">
        <v-col cols="5" id="search" class="d-flex">
          <v-text-field
            append-icon="$search"
            class="pt-0 mt-0 mr-4"
            placeholder="Search"
            v-model="search"
            dense
            outlined>
            <template v-slot:append v-if="search">
              <v-icon @click="search = '';fetchTrainer()">ri-close-line</v-icon>
            </template>
          </v-text-field>
          <v-btn
            small
            color="primary mr-3"
            elevation="0"
            @click="fetchTrainer">
            Search
          </v-btn>
        </v-col>
        <v-col cols="7" class="d-flex justify-end align-center">
          <v-btn
          v-if="$can('create', 'Trainer')"      
            small
            color="primary"
            elevation="0"
            @click="addTrainer">
            <v-icon>ri-add-line</v-icon> Trainer 
          </v-btn>
        </v-col>
      </v-row>

      <v-row>
        <v-col id="dt-program">
          <TableTrainer 
            :loading="isLoading" 
            :data="data"
            @refresh-list="fetchTrainer" 
            @detail="details"
            @update="update"
            @delete="deleteConfirm"
            @change_password="changePassword"
            @sorting="sorting" />
        </v-col>
      </v-row>

    </v-col>


    <v-dialog v-model="dialog.create" width="600" scrollable>
      <v-card class="rounded-lg">
        <v-card-title class="body-1 d-flex primary">
          <div class="white--text text-capitalize">
            Form Tambah Trainer
          </div>
          <v-spacer></v-spacer>
          <v-icon @click="closeDialog" color="white">ri-close-circle-line</v-icon>
        </v-card-title>
        <v-card-text style="height: 550px; overflow: auto;">
          <ValidationObserver ref="observer">
            <v-form>
              <v-row>
                <v-col cols="12">
                  <ValidationProvider ref="fullname" name="Fullname" rules="required" v-slot="{ errors }">
                    <v-text-field 
                      label="Nama Lengkap Trainer" 
                      outlined  
                      color="primary" 
                      hide-details="auto"
                      :error-messages="errors"
                      v-model="form.fullname">
                    </v-text-field>
                  </ValidationProvider>
                </v-col>
                <v-col cols="12">
                  <ValidationProvider ref="fullname" name="Email" rules="required|email" v-slot="{ errors }">
                      <v-text-field 
                        label="Email" 
                        outlined  
                        type="email"
                        color="primary" 
                        hide-details="auto"
                        :error-messages="errors"
                        v-model="form.email">
                      </v-text-field>
                  </ValidationProvider>
                </v-col>
                <v-col cols="12">
                  <ValidationProvider ref="phone_number" name="Phone Number" rules="required|numeric|min:10|max:15" v-slot="{ errors }">
                    <v-text-field 
                      label="Nomor Handphone"
                      outlined  
                      type="number" 
                      color="primary" 
                      hide-details="auto" 
                      :error-messages="errors"
                      v-model="form.phone_number">
                    </v-text-field>
                  </ValidationProvider>
                </v-col>
                <v-col cols="12">
                  <p class="mb-0">Gender</p>
                  <ValidationProvider ref="gender" name="Gender" rules="required" v-slot="{ errors }">
                    <v-radio-group row class="mt-2" hide-details="auto" v-model="form.gender" :error-messages="errors">
                      <v-radio color="primary" :value="1">
                        <template v-slot:label>
                          <div class="black--text">Laki - laki</div>
                        </template>
                      </v-radio>
                      <v-radio color="primary" :value="0">
                        <template v-slot:label>
                          <div class="black--text">Perempuan</div>
                        </template>
                      </v-radio>
                    </v-radio-group>
                  </ValidationProvider>
                </v-col>
                <v-col cols="12">
                  <p class="mb-0">Status Trainer</p>
                  <ValidationProvider ref="status" name="Status" rules="required" v-slot="{ errors }">
                    <v-radio-group row class="mt-2" hide-details="auto" v-model="form.status" :error-messages="errors">
                      <v-radio color="primary" value="active">
                        <template v-slot:label>
                          <div class="black--text">Aktif</div>
                        </template>
                      </v-radio>
                      <v-radio color="primary" value="inactive">
                        <template v-slot:label>
                          <div class="black--text">Tidak aktif</div>
                        </template>
                      </v-radio>
                    </v-radio-group>
                  </ValidationProvider>
                </v-col>
                <v-col cols="12">
                  <ValidationProvider name="Password" vid="confirm" rules="required|min:4|max:20" v-slot="{ errors }">
                    <v-text-field 
                      label="Password" 
                      outlined  
                      color="primary" 
                      autocomplete="off" 
                      hide-details="auto"
                      :type="show_password_one ? 'text' : 'password'"
                      :append-icon="show_password_one ? 'ri-eye-off-fill' : 'ri-eye-fill'"
                      :error-messages="errors"
                       v-model="form.password"
                      @click:append="show_password_one = !show_password_one">
                    </v-text-field>
                  </ValidationProvider>
                </v-col>
                <v-col cols="12">
                  <ValidationProvider name="Confirm Password" rules="required|min:4|max:20|confirmed:confirm" v-slot="{ errors }">
                    <v-text-field 
                      label="Confirm Password" 
                      outlined  
                      color="primary" 
                      autocomplete="off"
                      hide-details="auto" :type="show_password_two ? 'text' : 'password'"
                      :append-icon="show_password_two ? 'ri-eye-off-fill' : 'ri-eye-fill'"
                      :error-messages="errors"
                      v-model="form.confirm_password" 
                      @click:append="show_password_two = !show_password_two">
                    </v-text-field>
                  </ValidationProvider>
                </v-col>
              </v-row>
            </v-form>
          </ValidationObserver>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="grey lighten-2" class="text-capitalize mr-1" depressed width="120" height="40"
            :loading="isLoadingBtn" @click="closeDialog()">
            Tutup
          </v-btn>
          <v-btn color="primary" class="white--text text-capitalize" depressed width="120" height="40"
            :loading="isLoadingBtn" @click="saveTrainer()">
            Simpan
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- DIALOG GANTI PASSWORD -->
    <v-dialog v-model="dialog.change_password" persistent scrollable width="500">
      <v-card class="border-radius box-shadow">
        <v-card-title class="body-1 d-flex primary">
          <div class="white--text">
            Ganti Password Trainer <span class="text-uppercase font-weight-bold">({{ detailTrainer.fullname }})</span>
          </div>
          <v-spacer></v-spacer>
          <v-icon @click="closeDialog()" color="white">mdi-close-circle</v-icon>
        </v-card-title>
        <v-card-text class="pt-4">
          <ValidationObserver ref="observerPassword">
            <v-form>
              <v-row>
                <!-- <v-col cols="12" class="pb-1">
                  <div>Ganti Password Trainer <b class="text-uppercase">{{ fullname }}</b></div>
                </v-col> -->
                <!-- <v-col cols="12">
                  <ValidationProvider name="Current Password" rules="required|min:4|max:20" v-slot="{ errors }">
                    <v-text-field
                      label="Current Password"
                      outlined
                      autofocus
                      color="primary"
                      autocomplete="off"
                      tabindex="1"
                      hide-details="auto"
                      :type="show_password_one ? 'text' : 'password'"
                      :append-icon="show_password_one ? 'ri-eye-off-fill' : 'ri-eye-fill'"
                      :error-messages="errors"
                      v-model="form.current_password"
                      @click:append="show_password_one = !show_password_one">
                    </v-text-field>
                  </ValidationProvider>
                </v-col> -->
                <v-col cols="12">
                  <ValidationProvider name="Password" vid="confirm" rules="required|min:4|max:20" v-slot="{ errors }">
                    <v-text-field
                      label="New Password"
                      outlined
                      color="primary"
                      autocomplete="off"
                      tabindex="2"
                      hide-details="auto"
                      :type="show_password_two ? 'text' : 'password'"
                      :append-icon="show_password_two ? 'ri-eye-off-fill' : 'ri-eye-fill'"
                      :error-messages="errors"
                      v-model="form.password"
                      @click:append="show_password_two = !show_password_two">
                    </v-text-field>
                  </ValidationProvider>
                </v-col>
                <v-col cols="12">
                  <ValidationProvider name="Confirm Password" rules="required|min:4|max:20|confirmed:confirm" v-slot="{ errors }">
                    <v-text-field
                      label="Confirm New Password"
                      outlined
                      color="primary"
                      autocomplete="off"
                      tabindex="3"
                      hide-details="auto"
                      :type="show_password_three ? 'text' : 'password'"
                      :append-icon="show_password_three ? 'ri-eye-off-fill' : 'ri-eye-fill'"
                      :error-messages="errors"
                      @click:append="show_password_three = !show_password_three"
                      v-model="form.confirm_password">
                    </v-text-field>
                  </ValidationProvider>
                </v-col>
              </v-row>
            </v-form>
          </ValidationObserver>
        </v-card-text>
        <v-card-actions class="py-3">
          <v-spacer></v-spacer>
          <v-btn
            color="grey lighten-2"
            class="text-capitalize mr-1"
            depressed
            :loading="isLoadingBtn"
            @click="closeDialog()">
            Cancel
          </v-btn>
          <v-btn 
            color="primary"
            class="white--text text-capitalize"
            depressed
            :loading="isLoadingBtn"
            @click="saveChangePassword">
            Change Password
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- DIALOG DELETE -->
    <v-dialog v-model="dialog.delete" width="500">
      <v-card class="rounded-lg">
        <v-card-title class="body-1 d-flex primary">
          <div class="white--text text-capitalize">
            Konfirmasi Hapus Trainer
          </div>
        </v-card-title>
        <v-card-text class="my-4">
          <p>
            Apakah Anda yakin ingin menghapus data Trainer dibawah ini
          </p>

          <div class="d-flex align-center font-weight-bold" style="gap: 6px">
            <div class="text-uppercase ">{{ detailTrainer.fullname }}</div> -
            <v-chip
              :ripple="false"
              class="pa-1 text-capitalize"
              :color="detailTrainer.status == 'active' ? 'success' : 'error'"
              outlined
              label
              small
              v-text="detailTrainer.status == 'active' ? 'Aktif' : 'Tidak Aktif'"
            ></v-chip>
          </div>
          <div class="font-weight-medium">
            {{ detailTrainer.email }}
          </div>
        </v-card-text>
        <v-card-actions class="d-flex justify-center align-center">
          <v-btn 
            color="grey lighten-2" class="text-capitalize mr-1" depressed width="120"
            :loading="isLoadingBtn" @click="closeDialog()">
            Batal
          </v-btn>
          <v-btn 
            color="primary" class="white--text text-capitalize" depressed width="240"
            :loading="isLoadingBtn" @click="deleteTrainer()">
            Hapus
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar top v-model="snackbar.state" :color="snackbar.color" outlined :timeout="snackbar.timeout">
      <div v-html="snackbar.text"></div>
      <template v-slot:action="{ attrs }">
        <v-btn small icon color="error" v-bind="attrs" @click="snackbar.state = false">
          <v-icon>$close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>

  </v-row>
</template>

<script>
import TableTrainer from './components/Table.vue'
import { mapGetters} from 'vuex';

export default {
  name: "trainer-list",
  components: {
    TableTrainer
  },
  data: () => {
    return {
      isTrainer: true,
      isLoading: false,
      isLoadingBtn: false,
      dialog: {
        create: false,
        delete: false,
        change_password: false,
      },

      detailTrainer: {},

      show_password_one: false,
      show_password_two:  false,
      show_password_three:  false,

      form: {
        // gender: 1,
        // status: 'active'
      },
      snackbar: {
        state: false,
        text: "",
        color: "",
        timeout: 4000
      },

      search: "",
    }
  },
  watch: {
    // search() {
    //   this.fetchTrainer()
    // },
    'form.email' : function (value) {
      this.form.email = value.toLowerCase()
    }
  },
  computed: {
    ...mapGetters({ data: "trainer/data", params: "trainer/params" }),  
  },
  mounted() {
    let title = "Trainer"
    this.$emit("page-changed", 0, {
      title,
      link: '/trainer',
    });

    this.fetchTrainer()
  },
  methods: {
    addTrainer () {
      this.dialog.create = true
      this.$refs.observer.reset()
      this.$refs.observer.resetValidation()
    },
    closeDialog() {
      this.dialog.create = false
      this.dialog.delete = false
      this.dialog.change_password = false

      this.isLoadingBtn = false
      this.isLoading = false
    },
    fetchTrainer() {
      this.isLoading = true;
      this.$store.dispatch("trainer/list", { q: this.search, }).finally(() => {
        this.isLoading = false;
      });
    },
    sorting(value) {
      if (value === true) {
        this.dir = 'desc'
      } else { this.dir = 'asc' }
      this.isLoading = true;
      this.$store.dispatch("trainer/list", { q: this.search, sort: 'created_at', sort_type: 'desc' }).finally(() => {
        this.isLoading = false;
      });
    },

    details(item) {
      // console.log(item);
      this.$router.push(`/trainer/detail/${item.id}`,);
    },
    update(item) {
      // console.log(item);
      this.$router.push(`/trainer/update/${item.id}`,);
    },

    deleteConfirm(item) {
      // console.log(item);
      this.dialog.delete = true
      this.detailTrainer = item
    },

    changePassword(item) {
      // console.log(item);
      this.dialog.change_password = true
      this.detailTrainer = item
      this.form = {
        current_password: "",
        password: "",
        confirm_password: ""
      }
      this.$refs['observerPassword'].reset()
      this.$refs['observerPassword'].resetValidation()
    },

    async deleteTrainer() {
        this.isLoading = true;
        this.isLoadingBtn = true;
        await this.$store.dispatch("trainer/delete", this.detailTrainer.id)
        .then(() => {
          this.snackbar = {
            state: true,
            text: `Delete Trainer Succesfully`,
            color: "primary",
            timeout: 4000
          }
          this.dialog.delete = false
          this.fetchTrainer()
        }).catch(error => {

          if(Object.keys(error.response.data.validation).length > 0) {
            Object.keys(error.response.data.validation).forEach(item => {
              this.$refs[item].applyResult({
                errors: [error.response.data.validation[item]],
                valid: false,
                failedRules: {},
              });
            })

          }
        })
        .finally(() => {
          this.isLoading = false;
          this.isLoadingBtn = false;
          this.dialog.delete = false
          this.fetchTrainer()
        });
    },
    async saveChangePassword() {
      const isValid = await this.$refs.observerPassword.validate()
      if (isValid) {
        this.isLoadingBtn = true
        let data = {
          fullname: this.detailTrainer.fullname,
          email: this.detailTrainer.email,
          phone_number: this.detailTrainer.phone_number,
          gender: this.detailTrainer.gender.value,
          current_password: this.form.current_password,
          password: this.form.password,
          confirmation_password: this.form.confirm_password,
        }
        await this.axios.put(`/trainer/v1/company/update/${this.detailTrainer.id}`, data).then((response) => {
          let res = response.data
          if (res.status == 200) {
            this.closeDialog()
            this.snackbar = {
              state: true,
              text: `Change Password Successfully`,
              color: "primary",
              timeout: 4000
            }
            this.fetchTrainer()
          } else {
            this.closeDialog()
            this.snackbar = {
              state: true,
              text: `Change Password Failed`,
              color: "red",
              timeout: 4000
            }
          }
        }).catch(() => {
          this.error_message = this.$store.state.ContentValidation
          // if(Object.keys(error.response.data.validation).length > 0) {
          //   Object.keys(error.response.data.validation).forEach(item => {
          //     this.$refs[item].applyResult({
          //       errors: [error.response.data.validation[item]],
          //       valid: false,
          //       failedRules: {},
          //     });
          //   })

          // }
          this.isLoadingBtn = false
        })
      }
    },
    async saveTrainer() {
      let isValid = await this.$refs['observer'].validate()
      if(isValid) {
        this.isLoading = true;
        await this.axios.post("/trainer/v1/company/create", this.form)
        .then(() => {
          // console.log(res);
          this.snackbar = {
            state: true,
            text: `Create Trainer Succesfully`,
            color: "primary",
            timeout: 4000
          }
          this.dialog.create = false
          this.fetchTrainer()
          this.$refs.observer.resetValidation()
          this.$refs.observer.reset()
        }).catch(error => {
          // console.log(error.response.data);
          this.isLoading = false;
          if(Object.keys(error.response.data.validation).length > 0) {
            Object.keys(error.response.data.validation).forEach(item => {
              this.$refs[item].applyResult({
                errors: [error.response.data.validation[item]],
                valid: false,
                failedRules: {},
              });
            })
          }else {
            this.snackbar = {
            state: true,
            text: error.response.data.message,
            color: "error",
            timeout: 4000
          }
          }
        })
      }
    },
  }
}
</script>