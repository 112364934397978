<template>
  <div class="px-1">
    <div class="mt-5">
      <template v-if="process.run">
        <v-card loading outlined class="mt-3 elevation-0 rounded-lg">
          <div class="pa-4 text-center">
            <v-icon size="30">mdi-timer-sand</v-icon>
            <div class="pt-2">Content Loading . . .</div>
          </div>
        </v-card>
      </template>
      <template v-else>
        <div class="title black--text mb-2">
          Detail Trainer ({{ trainer_detail.fullname }})
        </div>
        <Breadcrumbs :items="breadcrumbs" />
        <v-card outlined class="pa-4 mt-3 mb-5 rounded-lg" v-if="Object.keys(trainer_detail).length > 0">
          <v-img cover width="250" height="250" class="rounded-xl" :src="require('@/assets/images/profile_picture.png')"></v-img>
          <div class="pt-3 text-body-1 font-weight-medium">
            Detail Trainer
          </div>
          <div class="pt-3 body-2">
            <div class="d-flex flex-wrap">
              <v-col cols="12" md="2" class="pt-2 px-0">
                <div class="grey--text text--darken-1">Trainer Name</div>
              </v-col>
              <v-col cols="12" md="10" class="pt-0 pt-md-2 px-0">
                <div>{{ trainer_detail.fullname }}</div>
              </v-col>
            </div>
            <div class="d-flex flex-wrap">
              <v-col cols="12" md="2" class="pt-2 px-0">
                <div class="grey--text text--darken-1">Email Address</div>
              </v-col>
              <v-col cols="12" md="10" class="pt-0 pt-md-2 px-0">
                <div>{{ trainer_detail.email }}</div>
              </v-col>
            </div>
            <div class="d-flex flex-wrap">
              <v-col cols="12" md="2" class="pt-2 px-0">
                <div class="grey--text text--darken-1">Phone Number</div>
              </v-col>
              <v-col cols="12" md="10" class="pt-0 pt-md-2 px-0">
                <div>{{ trainer_detail.phone_number }}</div>
              </v-col>
            </div>
            <div class="d-flex flex-wrap">
              <v-col cols="12" md="2" class="pt-2 px-0">
                <div class="grey--text text--darken-1">Description</div>
              </v-col>
              <v-col cols="12" md="10" class="pt-0 pt-md-2 px-0">
                <div>{{ trainer_detail.description ? trainer_detail.description : '-' }}</div>
              </v-col>
            </div>
            <div class="d-flex flex-wrap">
              <v-col cols="12" md="2" class="pt-2 px-0">
                <div class="grey--text text--darken-1">Gender</div>
              </v-col>
              <v-col cols="12" md="10" class="pt-0 pt-md-2 px-0 text-capitalize">
                <div>{{ trainer_detail.gender.text }}</div>
              </v-col>
            </div>
            <div class="d-flex flex-wrap">
              <v-col cols="12" md="2" class="pt-2 px-0">
                <div class="grey--text text--darken-1">Status</div>
              </v-col>
              <v-col cols="12" md="10" class="pt-0 pt-md-2 px-0">
                <v-chip
                  :ripple="false"
                  class="pa-1 text-capitalize"
                  :color="trainer_detail.status == 'inactive' ? 'error' : 'success'"
                  outlined
                  label
                  small
                  v-text="trainer_detail.status == 'active' ? 'Aktif' : 'Tidak Aktif'"
                ></v-chip>
              </v-col>
            </div>
            <div class="d-flex flex-wrap">
              <v-col cols="12" md="2" class="pt-2 px-0">
                <div class="grey--text text--darken-1">Created At</div>
              </v-col>
              <v-col cols="12" md="10" class="pt-0 pt-md-2 px-0">
                <div>{{ convertCreatedAt }}</div>
              </v-col>
            </div>
          </div>
        </v-card>
      </template>
    </div>
  </div>
</template>

<script>

import moment from 'moment'

export default {
  data() {
    return {
      title: "Detail Trainer",
      trainer_detail: {},
      process: {
        run: false,
      },
    };
  },
  watch: {
  },
  computed: {
    convertCreatedAt() {
      return moment(this.trainer_detail.created_at).format('DD MMMM YYYY HH:mm')
    }
  },
  created() {
    this.getDetailTrainer();
  },
  mounted() {
    let title=  "Detail Trainer"
    this.$emit("page-changed", 1, {
      title,
      link: '/trainer',
    });
  },
  methods: {
    async getDetailTrainer() {
      this.process.run = true;

      await this.axios.get(`trainer/v1/company/detail/${this.$route.params.id}`).then(
        (response) => {
          let res = response.data;
          this.trainer_detail = res.data;
          this.process.run = false;
          this.$store.state.process.run = false;
        }
      );
    },
  },
};
</script>

<style></style>