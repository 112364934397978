import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import router from "../router";

axios.defaults.baseURL = process.env.VUE_APP_BASE_URL;
axios.defaults.headers['Content-Type'] = 'application/json';

// create interceptor for checking token is expired or not
axios.interceptors.response.use(
  response => response,
  error => {
    if (error.response.status === 401) {
      localStorage.removeItem("ytc_auth");
      window.location.reload();
      setTimeout(() => {
        router.push("/login");
      } , 1000)
    }
    return Promise.reject(error);
  }
);

Vue.use(VueAxios, axios);
