<template>
  <v-row>
    <!-- Deskripsi -->
    <v-col cols="12">
      <div class="font-weight-medium mb-2">
        <span> Deskripsi </span>
      </div>
      <vue-editor
        :editorToolbar="customToolbar"
        v-model="data.description"
        class="overflow-y-auto"
        style="max-height: 400px"
        placeholder="Masukan deskripsi"
      ></vue-editor>
    </v-col>
    <!-- Boboboy -->
    <!-- <v-col cols="12">
      <v-col cols="12" class="px-0 py-0">
        <div class="font-weight-medium">Durasi Pengumpulan</div>
        <div class="font-weight-normal subtitle-2 grey--text mb-3">Pengaturan durasi button Pilih Berkas dalam format hari</div>
        <v-text-field
          placeholder="Silahkan isikan berapa hari durasi pengumpulan berkas EPM di atur"
          v-model.number="data.settings.max_upload_duration_evaluasi_praktek_mandiri">
        </v-text-field>
        <div class="primary--text" style="font-size: 10px;">*Default pengumpulan EPM 7 hari setelah mengakses Praktek Mandiri </div>
      </v-col>
    </v-col> -->
  </v-row>
</template>

<script>
import { VueEditor } from "vue2-editor";
import model from "../_mixins/model";

export default {
  components: { VueEditor },
  mixins: [model],
  data: () => ({
    overlay: 0.1,
    overlay_color: "secondary",
    file: null,
  }),
  computed: {
    customToolbar() {
      return [];
    },
  },
};
</script>
