export default { 
  Auth: require('./auth').default,
  Dashboard: require('./Dashboard').default,
  Program: require('./program').default,
  kelolaDashboard: require('./kelola-dashboard').default,
  Errors: require('./errors').default,
  Programs: require('./programs').default,
  TnA: require('./tnas').default,
  User: require('./user').default,
  Certif: require('./certificate').default,
  Notifikasi: require('./notification').default,
  Reseller: require('./reseller').default,
  Presensi: require('./presensi').default,
  Import: require('./import').default,
  Trainer: require('./trainer').default,
  Manage: require('./manage-certif').default,
  SettingPage: require('./settingPage').default,
  RedeemLog: require('./prakerja/redeem-log').default,
  StateLog: require('./prakerja/state-log').default,
  ExportQueue: require('./export-queue').default,
  MemberRedeem: require('./member-redeem').default,
}
