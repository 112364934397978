<template>
  <v-row justify="center">
    <v-col>
      <v-row>
        <v-col id="search" lg="5" class="d-flex justify-end align-center">
          <v-text-field
            append-icon="$search"
            @click:append="fetchListExportQueue"
            class="pt-0 mt-0 mr-4"
            placeholder="Silahkan cari nama antrean export . . ."
            v-model="export_name"
            @keydown.enter="fetchListExportQueue"
            outlined
          ></v-text-field>
          <v-btn
            @click="fetchListExportQueue"
            color="primary">
            Search
          </v-btn>
        </v-col>
      </v-row>

      <v-row>
        <v-col id="dt_program">
          <data-table
            :loading="table_loading"
            :data="data_queue_export"
            @refresh-list="fetchListExportQueue"
            @reset-export="resetExport"
            @export-file="downloadFileExport"
            @sorting="sorting"
          ></data-table>
        </v-col>
      </v-row>

    </v-col>


  </v-row>
</template>

<script>
import DataTable from "./_components/DataTable";
import { mapGetters } from "vuex";
export default {
  name: "program-list",
  components: { 
    'data-table': DataTable,
  },
  data() {
    return {
      export_name: "",
      sortBy: "status",
      sortDesc: "asc",
      isListEmpty: true,
      table_loading: false,
      data_queue_export: {}
    };
  },
  watch: {
    // export_name() {
    //   this.fetchListExportQueue();
    // }
  },
  computed: {
    ...mapGetters(["data"]),
    ...mapGetters(["user"]),
  },
  mounted() {
    this.$emit("page-changed", 0, { title: "Daftar Antrean Export", link: "/program" });

    this.fetchListExportQueue();
  },
  methods: {
    fetchListExportQueue() {
      this.table_loading = true;
      this.axios.get("users/v1/list-queue-export", {
        params: {
          q: this.export_name,
          page: this.data_queue_export.page,
          limit: this.data_queue_export.limit,
          sort: "created_at",
          dir: 'desc'
        }
      })
      .then((response) => {
        let res = response.data;
        this.data_queue_export = res.data
        this.table_loading = false;
      })
      .catch(() => {
        this.table_loading = false;
      });
    },
    resetExport(item) {
      console.log(item);
      this.table_loading = true;
      let data = {
        id: item.reference.arr_program_id,
        name: item.name
      }
      this.axios
          .post(`users/v1/export/queue_member_redeem`, data, {
            params: {
              sort: item.reference.sort,
              range_date_type: item.reference.range_date_type,
              limit: item.reference.limit,
              progress_start: item.reference.progress_start,
              progress_end: item.reference.progress_end,
              start_date: item.reference.start_date,
              end_date: item.reference.end_date
            }
          })
      .then((response) => {
        let res = response.data;
        console.log(res);
        this.table_loading = false;
      })
      .catch(() => {
        this.table_loading = false;
      });
    },
    downloadFileExport(item) {
      const link = document.createElement('a')
      link.href = item.url
      // link.download = `${item.title}.xlsx` //or any other extension
      link.setAttribute('download', `${item.title}.xlsx`) //or any other extension
      document.body.appendChild(link)
      link.click()
    },
    sorting(value) {
      console.log('sort',value);
      if (value === true) {
        this.sortBy = 'status';
        this.sortDesc = 'desc';  
      }else{
        this.sortBy = 'status';
        this.sortDesc = 'asc';
      }

      this.fetchListExportQueue();
    }
  }
};
</script>