<template>
  <v-row justify="center">
    <v-col cols="12">

      <v-row>
        <v-col cols="4" id="search">
          <v-text-field
            append-icon="$search"
            class="pt-0 mt-0 mr-4"
            placeholder="Search"
            v-model="name_program"
            @keydown.enter="fetchAll"
            dense
            outlined>
            <template v-slot:append v-if="name_program">
              <v-icon @click="name_program = '';fetchAll()">ri-close-line</v-icon>
            </template>
          </v-text-field>
        </v-col>
        <v-col id="search" cols="8" class="d-flex justify-end align-center">
          <v-btn
            small
            color="primary"
            depressed
            @click="dialog=true;listProgram()">
            <v-icon left>ri-add-line</v-icon> Program Pembelajaran Mandiri 
          </v-btn>
        </v-col>
      </v-row>

      <table-list
        :loading="process.run"
        :data="data"
        @refresh-list="fetch"
        @sorting="sorting">
      </table-list>

      <!-- DIALOG CREATE -->
      <v-dialog v-model="dialog" max-width="600px" persistent>
        <v-card>
          <v-card-title>
            Tambahkan Program
          </v-card-title>
          <v-divider></v-divider>
          <div>
            <v-card-text>
              <v-col cols="12">
                <div class="mb-2">Program</div>
                <v-autocomplete
                  v-model="program"
                  :items="list_program"
                  item-text="title"
                  item-value="id"
                  dense
                  filled
                  multiple
                  chips>
                </v-autocomplete>
              </v-col>                      
            </v-card-text>            
          </div>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn outlined color="primary" small elevation="0" @click="dialog=false; program = [];">
              Batal
            </v-btn>
            <v-btn
              class="white--text"
              color="primary"
              small
              elevation="0"
              @click="onSave()">
              Tambah 
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

    </v-col>
  </v-row>
</template>
<script>
import TableList from "./_components/table-list.vue"
export default {
  components: {
    "table-list": TableList
  },
  data: () => ({
    dialog: false,
    list_program: [],
    program: [],

    process: {
      run: false
    }
  }),

  mounted() {
    this.$emit("page-changed", 0, { title: "Pembelajaran Mandiri", link: "/program" });
  },

  methods: {
    listProgram() {
      this.axios
        .get("/program/v1/company/list",{
          params: {
            status: 'publish',
            limit: -1,
          }
        })
        .then((response) => {
          let res = response.data.data.list;
          this.list_program = res;
        });
    },

    async onSave() {

    }
  }
}
</script>