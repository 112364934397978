<template>
  <div>
    <v-row>
      <v-col cols="12" md="12" sm="12">
        <v-tabs v-model="tab">
          <v-tab
            class="pa-1 mr-2 text-capitalize"
            v-for="tab in ['Pengaturan Pop Up Quiz', 'Pop Up Quiz']"
            :key="tab"
            :disabled="tab == 'Pop up Quiz' && !isSaved"
          >
            <span> {{ tab }}</span>
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
          <v-tab-item>
            <v-row>
    
              <v-col cols="12">
                <v-alert type="warning" text outlined>
                  <strong>WAJIB</strong> untuk menambahkan data Pop Up Quiz terlebih dahulu ketika ingin menyimpan data Pengaturan Pop Up Quiz,
                  jikalau tidak pengaturan Pop Up Quiz tidak akan tersimpan.
                </v-alert>
              </v-col>
    
              <v-col cols="12">
                <div class="font-weight-medium mb-2">
                  <span> Tampilkan Pembahasan </span>
                </div>
                <v-radio-group v-model="setting.show_explanation">
                  <v-radio :value="true">
                    <span slot="label" class="color--text">Ya</span>
                  </v-radio>
                  <v-radio :value="false">
                    <span slot="label" class="color--text">Tidak</span>
                  </v-radio>
                </v-radio-group>
              </v-col>
    
              <v-col cols="12">
                <div class="font-weight-medium mb-2">
                  <span> Tampilkan Pembahasan dan Koreksi </span>
                </div>
                <v-radio-group v-model="setting.show_answer_explanation">
                  <v-radio :value="true">
                    <span slot="label" class="color--text">Ya</span>
                  </v-radio>
                  <v-radio :value="false">
                    <span slot="label" class="color--text">Tidak</span>
                  </v-radio>
                </v-radio-group>
              </v-col>
    
              <v-col cols="12">
                <div class="font-weight-medium mb-2">
                  <span> Skip </span>
                </div>
                <v-radio-group v-model="setting.skip">
                  <v-radio :value="true">
                    <span slot="label" class="color--text">Ya</span>
                  </v-radio>
                  <v-radio :value="false">
                    <span slot="label" class="color--text">Tidak</span>
                  </v-radio>
                </v-radio-group>
              </v-col>
    
              <v-col cols="12">
                <div class="font-weight-medium mb-2">
                  <span> Tampilkan Score </span>
                </div>
                <v-radio-group v-model="setting.show_score">
                  <v-radio :value="true">
                    <span slot="label" class="color--text">Ya</span>
                  </v-radio>
                  <v-radio :value="false">
                    <span slot="label" class="color--text">Tidak</span>
                  </v-radio>
                </v-radio-group>
              </v-col>
    
              <v-col cols="12">
                <div class="font-weight-medium mb-2">
                  <span> Atur Nilai Minimal </span>
                </div>
                <v-radio-group v-model="isMinimal">
                  <v-radio :value="true">
                    <span slot="label" class="color--text">Ya</span>
                  </v-radio>
                  <v-radio :value="false">
                    <span slot="label" class="color--text">Tidak</span>
                  </v-radio>
                </v-radio-group>
              </v-col>
    
              <v-col v-if="isMinimal || setting.minimum_score" cols="12">
                <div class="font-weight-medium mb-2">
                  <span> Nilai Minimal </span>
                </div>
                <v-text-field v-model="setting.minimum_score" placeholder="Masukkan Nilai Minimal"></v-text-field>
              </v-col>
    
              <v-col cols="12">
                <div class="font-weight-medium mb-2">
                  <span> Kesempatan Mengulang </span>
                </div>
                <v-select v-model="setting.retake" :items="retryOptions" placeholder="Pilih kesempatan mengulang"></v-select>
              </v-col>
    
              <v-col cols="12">
                <v-checkbox v-model.number="setting.is_random" :true-value="true" :false-value="false">
                  <span slot="label" class="color--text">Acak urutan soal</span>
                </v-checkbox>
              </v-col>
              
              <v-col cols="12">
                <div class="font-weight-medium mb-2">
                  <span> Gunakan Timer </span>
                </div>
                <v-radio-group v-model="setting.time.enable">
                  <v-radio :value="true">
                    <span slot="label" class="color--text">Ya</span>
                  </v-radio>
                  <v-radio :value="false">
                    <span slot="label" class="color--text" :value="false">Tidak</span>
                  </v-radio>
                </v-radio-group>
              </v-col>
    
              <v-col cols="12">
                <div class="font-weight-medium mb-2">
                  <span> Estimasi Durasi (Menit)</span>
                </div>
                <v-text-field v-model="setting.time.duration" placeholder="Masukkan estimasi durasi (menit)"></v-text-field>
              </v-col>
            </v-row>
          </v-tab-item>
          <v-tab-item>
            <!-- {{ gameplayData }} -->
            <v-row>
              <v-col cols="12">
                <v-row v-if="!quizs.length" justify="center">
                  <v-col md="4" sm="6">
                    <v-img class="col-10 mx-auto mt-12 mb-4" src="@/assets/images/data_empty.png"></v-img>
                    <h6 class="text-h6 text-center">Tidak ada Pop Up Quiz</h6>
                    <p class="text-center text--secondary">
                      klik tombol di bawah ini untuk menambahkan Pop Up Quiz baru
                    </p>
                    <p class="text-center">
                      <v-btn color="primary" @click="addQuiz">
                        <span> Tambah Pop Up Quiz </span>
                      </v-btn>
                    </p>
                  </v-col>
                </v-row>
                <v-expansion-panels v-else focusable>
                  <v-expansion-panel
                    v-for="(quiz, index) in quizs"
                    cols="12"
                    :key="index"
                    style="border: 1px solid #9db0c6;"
                    class="mb-2"
                  >
                    <v-expansion-panel-header class="font-weight-bold">
                      <h3>Pop Up Quiz </h3>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-row class="mt-4">
                        <v-col cols="12">
                          <div class="font-weight-medium mb-2">
                            <span> Tipe Pop Up Quiz </span>
                          </div>
                          <v-select 
                            v-model="quiz.type" 
                            :items="typeOptions" 
                            placeholder="Pilih tipe pop up quiz"
                          >
                          </v-select>
                        </v-col>

                        <v-col cols="12">
                          <div class="font-weight-medium mb-2">
                            <span> Perintah Pop Up Quiz </span>
                          </div>
                          <v-text-field
                            hide-details="auto"
                            outlined
                            filled
                            v-model="dataGameplay.introduction"
                            placeholder="Masukkan kalimat perintah pop up quiz">
                          </v-text-field>
                        </v-col>
          
                        <v-col cols="12" class="pb-0"> <v-divider /></v-col>
                        <v-col cols="12">
                          <pop-quiz-choices v-if="quiz.type == 'multiple_choice'" v-model="quizs[index]" ref="multiple_choice" @click:submit="submitQuiz"/>
                          <pop-quiz-jigsaw v-else-if="quiz.type == 'jigsaw'"  v-model="quizs[index]" ref="jigsaw"  @click:submit="submitQuiz"/>
                          <pop-quiz-pairing v-else-if="quiz.type == 'matching_word'"  v-model="quizs[index]" ref="matching_word" @click:submit="submitQuiz"/>
                          <pop-quiz-word-search v-else-if="quiz.type == 'finding_word'"  v-model="quizs[index]" ref="finding_word" @click:submit="submitQuiz"/>
                        </v-col>
                      </v-row>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-col>
              <!-- <v-col v-if="quizs.length != 0" cols="12">
                <v-btn color="primary" outlined @click="addQuiz">
                  <v-icon left>$add</v-icon>
                  Tambah Quiz
                </v-btn>
              </v-col> -->
          
              <v-dialog v-model="delete_quiz.show" persistent max-width="600px">
                <v-card>
                  <v-card-title>
                    <span> <v-icon>$warning</v-icon> Hapus Pop Up Quiz </span>
                  </v-card-title>
                  <v-divider></v-divider>
                  <div>
                    <v-card-text>
                      Apakah anda ingin menghapus Pop Up Quiz ini ?
                    </v-card-text>
                  </div>
                  <v-divider></v-divider>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn :disabled="delete_quiz.loading" outlined color="primary" large @click="cancelDeleteQuiz">
                      Batal
                    </v-btn>
                    <v-btn :loading="delete_quiz.loading" class="white--text" color="primary" large @click="confirmDeleteQuiz">
                      Hapus
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-row>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
      <v-col cols="12" md="12" sm="12">

        <!-- <v-alert type="info" text outlined dense>
          <div style="font-size: 13px;">
            Check kembali data Pengaturan Pop Up Quiz dan Pop Up Quiz, apabila sudah benar dan sesuai
            dan tidak ada data yang kosong silahkan klik button <strong>Simpan Quiz.</strong> 
          </div>
        </v-alert> -->

        <div class="text-right" v-if="Object.keys(gameplayData).length < 1">
          <v-btn outlined class="priamry mr-4" :loading="submiting" @click="$router.push({ name: 'program-detail', params: { id: $route.params.program } })">Batal</v-btn>
          <v-btn class="primary" :loading="submiting" @click="saveQuiz(true)">Simpan Quiz</v-btn>
        </div>
        <div class="text-right" v-show="Object.keys(gameplayData).length > 0 && tab == 1">
          <v-btn outlined class="priamry mr-4" :loading="submiting" @click="$router.push({ name: 'program-detail', params: { id: $route.params.program } })">Batal</v-btn>
          <v-btn class="primary" :loading="submiting" @click="saveQuiz(true)">Simpan Quiz</v-btn>
        </div>
      </v-col>
    </v-row>


    <v-snackbar top v-model="snackbar.state" :timeout="5000" color="primary" outlined>
        <div v-html="snackbar.text"></div>
      <template v-slot:action="{ attrs }">
        <v-btn small icon color="error" v-bind="attrs" @click="snackbar.state = false">
          <v-icon>$close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import model from "../_mixins/model";
import PopQuizChoices from "./PopQuiz/PopQuizChoices";
import PopQuizJigsaw from "./PopQuiz/PopQuizJigsaw";
import PopQuizPairing from "./PopQuiz/PopQuizPairing";
import PopQuizWordSearch from "./PopQuiz/PopQuizWordSearch";

export default {
  components: {
    PopQuizChoices,
    PopQuizJigsaw,
    PopQuizPairing,
    PopQuizWordSearch,
  },
  mixins: [model],
  props: {
    gameplayData: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    tab: null,
    submiting: false,
    delete_quiz: { loading: false, show: false, index: null },
    snackbar: {
      state: false,
      text: "",
    },
    quizs: [],
    detailQuizs: [],
    dataGameplay: {
      introduction: "",
      type: "",
      setting: {},
      gameplays: []
    },
    isMinimal: null,
    setting: {
      show_explanation: null,
      show_answer_explanation: null,
      minimum_score: null,
      retake: null,
      skip: null,
      show_score: null,
      is_random: false,
      time: {
        enable: null,
        duration: null
      }
    }
    // quiz: ,
  }),
  computed: {
    typeOptions() {
      return [
        { text: "Multiple Choice", value: "multiple_choice" },
        { text: "Jigsaw", value: "jigsaw" },
        { text: "Cari Kata", value: "finding_word" },
        { text: "Menjodohkan", value: "matching_word" },
      ];
    },
    retryOptions() {
      return [
        { value: 1, text: "1 x" },
        { value: 10, text: "10 x" },
        { value: 50, text: "50 x" },
        { value: 100, text: "100 x" },
        { value: 0, text: "Tanpa mengulang" },
        { value: -1, text: "Mengulang tanpa batas" },
      ];
    },
  },
  watch: {},
  created(){},
  mounted() {
    if (this.$route.name == "activity-edit" && Object.keys(this.gameplayData).length > 0) {
      this.isMinimal = this.data.setting.minimum_score == null ? false : true
      this.setting = this.data.setting
      this.dataGameplay = this.data
      // this.handleDetail()
      this.editQuiz()
    }else {
      this.addQuiz()
    }
  },
  methods: {
    addQuiz() {
      this.quizs.push({});
    },
    editQuiz() {
      this.quizs.push(this.gameplayData);
    },
    deleteQuiz(index) {
      this.delete_quiz.show = true;
      this.delete_quiz.index = index;
    },
    cancelDeleteQuiz() {
      this.delete_quiz.show = false;
      this.delete_quiz.index = null;
    },
    confirmDeleteQuiz() {
      this.quizs.splice(this.delete_quiz.index, 1);
      this.delete_quiz.show = false;
      this.delete_quiz.index = null;
    },

    submitQuiz (payload) {

      let settingdata = {
        show_explanation: this.setting.show_explanation,
        show_answer_explanation: this.setting.show_answer_explanation,
        minimum_score: Number(this.setting.minimum_score),
        retake: this.setting.retake,
        skip: this.setting.skip,
        show_score: this.setting.show_score,
        is_random: this.setting.is_random,
        time: {
          enable: this.setting.time.enable,
          duration: Number(this.setting.time.duration)
        }
      }

      let data = {
        introduction: this.dataGameplay.introduction,
        type: payload ? payload.type : this.gameplayData.type,
        setting: settingdata,
        gameplays: payload ? payload.gameplays : this.gameplayData.gameplays
      }

      this.dataGameplay = data

      if(payload.saving) {
        this.snackbar.text = `Soal berhasil disimpan.`;
        this.snackbar.state = true;
      }else {
        this.saveQuiz(false)
      }


    },

    validation() {
      let isValid = true

      this.snackbar.text = ""

      if(this.dataGameplay.introduction == null || this.dataGameplay.introduction == '') {
        isValid  =false
        this.snackbar.text += "Perintah Pop Up Quiz tidak boleh kosong\n"
        this.snackbar.state = true
      }else if(this.setting.show_explanation == null) {
        isValid  =false
        this.snackbar.text += "Pengaturan Tampilkan Pembahasan belum diatur\n"
        this.snackbar.state = true
      }else if(this.setting.show_answer_explanation == null) {
        isValid  =false
        this.snackbar.text += "Pengaturan Tampilkan Pembahasan dan Koreksi belum diatur\n"
        this.snackbar.state = true
      }else if(this.setting.skip == null) {
        isValid  =false
        this.snackbar.text += "Pengaturan Skip belum diatur\n"
        this.snackbar.state = true
      }else if(this.setting.show_score == null) {
        isValid  =false
        this.snackbar.text += "Pengaturan Tampilkan Score belum diatur\n"
        this.snackbar.state = true
      }else if(this.setting.retake == null) {
        isValid  =false
        this.snackbar.text += "Pengaturan Kesempatan mengulang belum diatur\n"
        this.snackbar.state = true
      }
      else if(this.setting.time.enable == null) {
        isValid  =false
        this.snackbar.text += "Pengaturan Gunakan Timer belum diatur\n"
        this.snackbar.state = true
      }else if(this.setting.time.duration == null) {
        isValid  =false
        this.snackbar.text += "Pengaturan Estimasi Durasi belum diatur\n"
        this.snackbar.state = true
      }else if(this.isMinimal == null) {
        isValid  =false
        this.snackbar.text += "Pengaturan Nilai Minimal belum diatur\n"
        this.snackbar.state = true
      }

      return isValid
    },

    async saveQuiz(state) {

      if(this.dataGameplay.gameplays.length < 1) {
        this.snackbar.state = true
        this.snackbar.text = "Silahkan tambahkan Pop Up Quiz terlebih dahulu dan simpan soal terbaru."
        return
      }

      let isValid = this.validation()

      let dataQuiz = {
        introduction: this.dataGameplay.introduction,
        type: this.dataGameplay.type,
        setting: this.setting,
        gameplays: this.dataGameplay.gameplays
      }

      if (isValid) {
        this.submiting = true
  
        try {
          const payload = {
            program: this.$route.params.program,
            topic: this.$route.params.topic,
            id: this.$route.params.id,
            data: dataQuiz,
          };
    
          await this.$store.dispatch("activity/edit_quiz", payload);
    
          this.snackbar.text = state ? `Data Pop Up Quiz berhasil disimpan.` : `Data Pop Up Quiz berhasil diperbarui.`;
          this.snackbar.state = true;
          setTimeout(() => {
            this.$router.push({ name: "program-detail", params: { id: payload.program } });
            this.submiting = false;
          }, 1000);
  
        } catch (error) {
          console.error(error);
          this.snackbar.text = `Error: ${error.message}.`;
          this.snackbar.state = true;
          this.submiting = false;
        }
      }

    },
  },
};
</script>
