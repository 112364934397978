// src/lib/vuetify.js
import Vue from 'vue'
import Vuetify from 'vuetify'
import 'remixicon/fonts/remixicon.css'
// import mdi icon
import '@mdi/font/css/materialdesignicons.css'
import opts from './prakerja.vuetify'

Vue.use(Vuetify)

export default new Vuetify(opts)
