var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-col',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{attrs:{"outlined":""}},[_c('v-form',{ref:"form",model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-card-title',{staticClass:"pa-8 pb-2"},[_vm._v("Topik")]),_c('div',{staticClass:"px-8 pb-4"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"font-weight-medium mb-2"},[_vm._v("Judul Topik")]),_c('v-text-field',{attrs:{"outlined":"","placeholder":"Masukkan judul topik","required":"","rules":_vm.rules('Judul Topik', 'required').concat( [_vm.validate]),"disabled":_vm.isDisabled},model:{value:(_vm.title_topik),callback:function ($$v) {_vm.title_topik=$$v},expression:"title_topik"}})],1),(!_vm.isLoading && _vm.$route.name == 'activity-edit')?_c('v-col',{staticClass:"my-0 py-0",attrs:{"cols":"12"}},[_c('v-row',[_c('v-col',{staticStyle:{"border-right":"1px solid grey"},attrs:{"cols":"6"}},[_c('div',{staticClass:"d-flex justify-space-between align-center"},[_c('div',[_c('div',{staticClass:"d-flex justify-start font-weight-medium"},[_c('div',[_vm._v("Durasi Topik Selanjutnya")]),_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"small":"","right":""}},'v-icon',attrs,false),on),[_vm._v(" ri-question-line ")])]}}],null,false,1394170251)},[_c('div',{staticStyle:{"font-size":"12px"}},[_vm._v(" Durasi topik digunakan untuk mengatur rentang waktu user dapat mengakses topik selanjutnya. "),_c('br'),_vm._v(" Dihitung sejak penyelesaian aktivitas terakhir pada topik ini. ")])])],1),_c('div',{staticClass:"grey--text",staticStyle:{"font-size":"11px"}},[_vm._v("Pengaturan durasi topik dalam format hari")]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.duration),expression:"duration"}],staticClass:"primary--text",staticStyle:{"font-size":"12px"}},[_vm._v("Pengaturan durasi topik selanjutnya diatur selama "),_c('span',{staticClass:"font-weight-medium",staticStyle:{"font-size":"14px !important"}},[_vm._v(_vm._s(_vm.delay_topic_duration))]),_vm._v(" hari")])]),_c('v-chip',{attrs:{"color":_vm.duration ? 'green' : 'red',"outlined":""}},[_vm._v(" "+_vm._s(_vm.duration ? 'Digunakan' : 'Tidak digunakan')+" ")])],1)]),_c('v-col',{attrs:{"cols":"6"}},[_c('div',{staticClass:"d-flex justify-space-between align-center"},[_c('div',[_c('div',{staticClass:"d-flex justify-start font-weight-medium"},[_c('div',[_vm._v("Verifikasi Wajah")]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"small":"","right":""}},'v-icon',attrs,false),on),[_vm._v(" ri-question-line ")])]}}],null,false,1394170251)},[_c('div',{staticStyle:{"font-size":"12px"}},[_vm._v("Verifikasi Wajah: Data wajah akan digunakan untuk mengonfirmasi keaslian identitas peserta")])])],1),_c('div',{staticClass:"grey--text",staticStyle:{"font-size":"13px"}},[_vm._v("Verifikasi wajah untuk peserta kelas prakerja")])]),_c('v-chip',{attrs:{"color":_vm.use_liveness ? 'green' : 'red',"outlined":""}},[_vm._v(" "+_vm._s(_vm.use_liveness ? 'Digunakan' : 'Tidak digunakan')+" ")])],1)])],1)],1):_vm._e(),(!_vm.isLoading && _vm.$route.name != 'activity-edit')?_c('v-col',{staticClass:"my-0 py-0",attrs:{"cols":"12"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"10"}},[_c('div',{staticClass:"d-flex justify-start font-weight-medium"},[_c('div',[_vm._v("Durasi Topik Selanjutnya")]),_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"small":"","right":""}},'v-icon',attrs,false),on),[_vm._v(" ri-question-line ")])]}}],null,false,1394170251)},[_c('div',{staticStyle:{"font-size":"12px"}},[_vm._v(" Durasi topik digunakan untuk mengatur rentang waktu user dapat mengakses topik selanjutnya. "),_c('br'),_vm._v(" Dihitung sejak penyelesaian aktivitas terakhir pada topik ini. ")])])],1),_c('div',{staticClass:"grey--text",staticStyle:{"font-size":"13px"}},[_vm._v("Pengaturan durasi topik dalam format hari")]),_c('v-expand-transition',[_c('v-text-field',{directives:[{name:"show",rawName:"v-show",value:(_vm.duration),expression:"duration"}],staticClass:"my-2",attrs:{"outlined":"","placeholder":"Durasi dalam hari. Misal: 1","type":"number","disabled":_vm.isDisabled},model:{value:(_vm.delay_topic_duration),callback:function ($$v) {_vm.delay_topic_duration=$$v},expression:"delay_topic_duration"}})],1),_c('div',{staticClass:"primary--text",staticStyle:{"font-size":"10px"}},[_vm._v("*Default durasi topik selanjutnya 1 hari setelah pengerjaan setiap topik")])],1),_c('v-col',[_c('v-switch',{attrs:{"inset":"","disabled":_vm.isDisabled,"label":_vm.duration ? 'Aktif' : 'Tidak Aktif'},model:{value:(_vm.duration),callback:function ($$v) {_vm.duration=$$v},expression:"duration"}})],1)],1)],1):_vm._e(),(!_vm.isLoading && _vm.$route.name != 'activity-edit')?_c('v-col',{staticClass:"my-0 py-0",attrs:{"cols":"12"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"10"}},[_c('div',{staticClass:"d-flex justify-start font-weight-medium"},[_c('div',[_vm._v("Verifikasi Wajah")]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"small":"","right":""}},'v-icon',attrs,false),on),[_vm._v(" ri-question-line ")])]}}],null,false,1394170251)},[_c('div',{staticStyle:{"font-size":"12px"}},[_vm._v("Verifikasi Wajah: Data wajah akan digunakan untuk mengonfirmasi keaslian identitas peserta")])])],1),_c('div',{staticClass:"grey--text",staticStyle:{"font-size":"13px"}},[_vm._v("Verifikasi wajah untuk peserta kelas prakerja")])]),_c('v-col',[_c('v-switch',{attrs:{"inset":"","disabled":_vm.isDisabled,"label":_vm.use_liveness ? 'Aktif' : 'Tidak Aktif'},model:{value:(_vm.use_liveness),callback:function ($$v) {_vm.use_liveness=$$v},expression:"use_liveness"}})],1)],1)],1):_vm._e()],1)],1),(this.$route.name == 'activity-edit')?_c('v-card-actions',{staticClass:"background"},[_c('v-spacer')],1):_c('v-card-actions',{staticClass:"background"},[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","loading":_vm.submitting},on:{"click":function($event){return _vm.save()}}},[_vm._v("Simpan")])],1)],1)],1)],1),(_vm.isLoading)?_c('v-col',{staticClass:"text-center",attrs:{"cols":"12"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary"}})],1):_vm._l((_vm.activities),function(activity,index){return _c('activity-form',{key:activity.id,ref:"actForm",refInFor:true,attrs:{"seq":index + 1,"activity":activity,"submiting":_vm.submitting},on:{"click:submit":_vm.submit,"update:activity":function($event){return _vm.$set(_vm.activities, index, $event)},"click:cancel":function($event){return _vm.$router.push({ name: 'program-detail', params: { id: _vm.$route.params.program } })}}})}),_c('v-snackbar',{attrs:{"top":"","timeout":_vm.timeout,"color":"primary","outlined":""},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"small":"","icon":"","color":"error"},on:{"click":function($event){_vm.snackbar.state = false}}},'v-btn',attrs,false),[_c('v-icon',[_vm._v("$close")])],1)]}}]),model:{value:(_vm.snackbar.state),callback:function ($$v) {_vm.$set(_vm.snackbar, "state", $$v)},expression:"snackbar.state"}},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.snackbar.text)}})])],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }