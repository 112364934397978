var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"transparent",attrs:{"loading":_vm.isLoading,"headers":_vm.headers,"item-key":'id',"items":_vm.data.list,"items-per-page":_vm.data.limit,"page":_vm.data.page,"mobile-breakpoint":0,"loading-text":"Loading... Please wait!","sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"footer-props":{
    'items-per-page-text':'Row per page : '
  },"server-items-length":_vm.data.total},on:{"update:itemsPerPage":function($event){return _vm.$set(_vm.data, "limit", $event)},"update:items-per-page":function($event){return _vm.$set(_vm.data, "limit", $event)},"update:page":function($event){return _vm.$set(_vm.data, "page", $event)},"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
  var item = ref.item;
return [_c('tr',{staticClass:"flat-b-1 rounded-lg"},[_c('td',{staticClass:"white text-capitalize font-weight-bold rounded-l-lg"},[_c('router-link',{staticClass:"color--text white font-weight-bold text-capitalize",attrs:{"to":{ name: 'list-program-trainer', params: { id: item.id } }},domProps:{"textContent":_vm._s(item.fullname)}})],1),_c('td',{staticClass:"white"},[_vm._v(_vm._s(item.email))]),_c('td',{staticClass:"white"},[_vm._v(_vm._s(item.phone_number))]),_c('td',{staticClass:"white text-capitalize"},[_vm._v(_vm._s(item.gender.text))]),_c('td',{staticClass:"white text-capitalize text-center"},[_c('v-chip',{staticClass:"pa-1 text-capitalize",attrs:{"ripple":false,"color":item.status == 'active' ? 'success' : 'error',"outlined":"","label":"","small":""},domProps:{"textContent":_vm._s(item.status == 'active' ? 'Aktif' : 'Tidak aktif')}})],1),_c('td',{staticClass:"white rounded-r-lg",staticStyle:{"width":"5%"}},[_c('div',{staticClass:"d-flex flex-row"},[_c('can',{attrs:{"I":"publish","a":"Program"}},[_c('v-btn',{attrs:{"title":"Update Trainer","icon":""},on:{"click":function($event){return _vm.$emit('update', item)}}},[_c('v-icon',[_vm._v("ri-pencil-line")])],1)],1),_c('can',{attrs:{"I":"publish","a":"Program"}},[_c('v-btn',{attrs:{"title":"Delete Trainer","icon":""},on:{"click":function($event){return _vm.$emit('delete', item)}}},[_c('v-icon',[_vm._v("ri-delete-bin-line")])],1)],1),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var on = ref.on;
  var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"title":"more","icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("ri-more-line")])],1)]}}],null,true)},[_c('v-list',[(_vm.$can('detail', 'Program'))?_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){return _vm.$emit('detail', item)}}},[_c('v-list-item-title',[_vm._v("Detail")])],1):_vm._e(),(_vm.$can('change_password', 'Program'))?_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){return _vm.$emit('change_password', item)}}},[_c('v-list-item-title',[_vm._v("Ganti Password")])],1):_vm._e()],1)],1)],1)])])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }