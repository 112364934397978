<template>
  <div>
    <v-row>
			<!-- <v-col id="search" lg="6" class="d-flex align-center">          				
				<v-text-field
					outlined
					append-icon="$search"
					class="pt-0 mt-0 mr-4"
					placeholder="Search"
					v-model="searching"
					solo
					flat
          dense
					@keydown.enter="fetchRedeemLog"
				>
					<template v-slot:append v-if="searching">
						<v-icon @click="searching = '';fetchRedeemLog()">ri-close-line</v-icon>
					</template>    
				</v-text-field>
        <v-btn
          small
          color="primary mr-3"
          elevation="0"
          @click="fetchRedeemLog">
          Search
        </v-btn>      
			</v-col> -->
      <v-col justify="end" align="end">
        <v-btn
          class="white--text mr-3"
          color="primary"
          small
          :loading="process.run"
          @click="showFilter"
        >
          <v-icon left>ri-filter-3-line</v-icon>
          Filter
        </v-btn>
        <v-btn
          color="primary"
          small
          @click="filterExportData"
        >
          <v-icon left>ri-file-excel-line</v-icon>
          <span>Export</span>
        </v-btn>
      </v-col>
		</v-row>
    <v-row>
      <v-col id="dt_program">
        <table-list
          :loading="loading"
          :data="redeemLogs"
          @detail="detail"
          />
          <!-- @change="change"
          @sorting="sorting"
          @voucher="voucher"
          @refresh-list="fetchRedeemLog" -->
      </v-col>
    </v-row>

    <!-- DIALOG FILTER EXPORT -->
    <dialog-export
      v-model="dialogExport"
      :filter="filter"
      :loadingExport="loadingExport"
      @close="closeFilterExport"
      @export="exportData"
    />

    <!-- DIALOG FILTER DATA -->
    <v-dialog v-model="dialog.filter" persistent width="650">
      <v-card class="box-shadow rounded-lg">
        <v-card-title
          class="body-2 text-first d-flex mb-4"
          style="background-color: #F05326;"
        >
          <span class="white--text">
            Filter
          </span>
          <v-spacer></v-spacer>
          <v-icon @click="dialog.filter = false" color="white" :disabled="process.run">mdi-close-circle</v-icon>
        </v-card-title>
        <v-card-text>
          <v-text-field
            outlined
            dense
            flat
            v-model="filter.unique_id"
            label="Please Input License Code"
            class="mb-4"
            color="primary">
          </v-text-field>
          <v-text-field
            outlined
            dense
            flat
            v-model="filter.redeem_code"
            label="Please Input Kode Redeem"
            class="mb-4"
            color="primary">
          </v-text-field>
          <v-autocomplete
            outlined
            dense
            flat
            v-model="filter.member_id"
            :search-input.sync="searchMember"
            :items="memberList"
            hide-no-data
            hide-selected
            label="Please Select Member"
            class="mb-4"
            item-value="id"
            item-text="fullname"
            color="primary">
          </v-autocomplete>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn 
            depressed
            small
            :loading="process.run"
            class="text-capitalize"
            @click="closeFilter('close')">
            Close
          </v-btn>
          <v-btn 
            depressed
            small
            color="red"
            class="white--text text-capitalize"
            :loading="process.run"
            @click="closeFilter('reset')">
            Reset
          </v-btn>
          <v-btn 
            depressed
            small
            color="#EF6C00" 
            class="white--text text-capitalize"
            :loading="process.run"
            @click="fethcDataRedeemLog()">
            Filter
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- DIALOG STATUS PRAKERJA -->
    <v-dialog v-model="dialogStatusPrakerja" max-width="1300px" persistent scrollable>
      <v-card>
        <v-card-title>
          <span>Detail Status Prakerja</span>
        </v-card-title>
        <v-card-subtitle class="pt-2">
          Di redeem pada <span class="font-weight-bold">{{ convertCreatedAt(redeemLogsDetail.CreatedAt) }}</span>
        </v-card-subtitle>
        <v-divider></v-divider>
        <v-card-text style="height: 550px">
          <v-row>
            <v-col>
              <div class="font-weight-bold text-h6 mb-3">Data Member</div>
              <v-simple-table border>
                <template v-slot:default>
                  <tbody>
                    <tr>
                      <th class="px-0">Nama Member</th>
                      <td>{{ redeemLogsDetail.member.fullname }}</td>
                    </tr>
                    <tr>
                      <th class="px-0">Email</th>
                      <td>{{ redeemLogsDetail.member.email }}</td>
                    </tr>
                    <tr>
                      <th class="px-0">No. Handphone</th>
                      <td>{{ redeemLogsDetail.member.phone }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
            <v-col>
              <div class="font-weight-bold text-h6 mb-3">Data Reference</div>
              <v-simple-table border>
                <template v-slot:default>
                  <tbody>
                    <tr>
                      <th class="px-0">Kode License</th>
                      <td>{{ redeemLogsDetail.reference.unique_id }}</td>
                    </tr>
                    <tr>
                      <th class="px-0">Sequence</th>
                      <td>{{ redeemLogsDetail.reference.sequence }}</td>
                    </tr>
                    <tr>
                      <th class="px-0">From</th>
                      <td>{{ redeemLogsDetail.reference.from }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <v-row>
            <v-col>
              <div class="font-weight-bold text-h6 mb-3">Data Prakerja Request</div>
              <v-divider/>
              <v-simple-table border>
                <template v-slot:default>
                  <tbody>
                    <tr>
                      <th class="px-0">Kode Redeem</th>
                      <td>{{ parseDataRequestBody(redeemLogsDetail.request.body).redeem_code }}</td>
                    </tr>
                    <tr>
                      <th class="px-0">Email</th>
                      <td>{{ parseDataRequestBody(redeemLogsDetail.request.body).email }}</td>
                    </tr>
                    <tr>
                      <th class="px-0">Sequence</th>
                      <td>{{ parseDataRequestBody(redeemLogsDetail.request.body).sequence }}</td>
                    </tr>
                    <tr>
                      <th class="px-0">URI Redirect</th>
                      <td>{{ parseDataRequestBody(redeemLogsDetail.request.body).redirect_uri }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
            <v-col>
              <div class="font-weight-bold text-h6 mb-3">Data Prakerja Response</div>
              <v-divider/>
              <v-simple-table border>
                <template v-slot:default>
                  <tbody>
                    <tr v-for="(item, i) in redeemLogsDetail.response" :key="i">
                      <th class="px-0 text-capitalize">{{ item.Key }}</th>
                      <td v-if="typeof item.Value == 'object'">
                        <div v-for="(value) in item.Value" :key="value.key">
                          <div v-if="typeof value.Value == 'object'">
                            <div v-for="(val) in value.Value" :key="val.key">
                              <span class="font-weight-bold">{{ value.Key }} {{ val.Key }}</span> : {{ val.Value }}
                            </div>
                          </div>
                          <div v-else>
                            <span class="font-weight-bold">{{ value.Key }}</span> : {{ value.Value }}
                          </div>
                        </div>
                      </td>
                      <td v-else>
                        {{ item.Value }}
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="white--text"
            color="primary"
            small
            elevation="0"
            :loading="loading"
            @click="redeemLogsDetail = {} ; dialogStatusPrakerja = false"
          >
            Tutup
          </v-btn>
        </v-card-actions>
        <v-divider></v-divider>
      </v-card>
    </v-dialog>

    <!-- SNACKBAR NOTIFICATION -->
    <v-snackbar 
      top 
      v-model="snackbar.state"
      color="primary"
      outlined
      :timeout="snackbar.timeout">
      <div v-html="snackbar.text"></div>
      <template v-slot:action="{ attrs }">
        <v-btn
          small
          icon
          color="error"
          v-bind="attrs"
          @click="snackbar.state = false"
        >
          <v-icon>$close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import TableList from "./components/table-list.vue";
import DialogExport from "./components/dialog-export.vue";
import moment from "moment";
export default {
  components: {
    'table-list': TableList,
    'dialog-export': DialogExport
  },
  data:() => ({
    loading: false,
    loadingExport: false,
    dialogStatusPrakerja: false,
    dialogExport: false,

    dialog: {
			filter: false,
		},

		process: {
			run: false,
		},

		memberList: [],

    filter: {
      start_date: "",
      end_date: "",
      start_time: "",
      end_time: "",

      unique_id: "",
			redeem_code: "",
			member_id: "",
    },
    snackbar: {
      state: false,
      text: "",
      timeout: 5000,
    },

    searchMember: "",
    searching: "",
    redeemLogs: [],
    redeemLogsDetail: {},
  }),
  watch: {
    searchMember(val) {
      if (val && val.length > 2) {
        this.fetchMemberRedeem();
      }
    },
  },
  created() {
    this.$emit("page-changed", 0, {
			title: "Log Redeem",
			link: this.$route.path,
		});
    // this.fetchRedeemLog();
  },
  computed: {
    convertCreatedAt() {
      return (date) => {
        return moment(date).locale('id').format("DD MMMM YYYY HH:mm:ss");
      };
    }
  },
  methods: {
    async showFilter() {
			// await this.fetchMemberRedeem();
			this.dialog.filter = true;
		},

		closeFilter(state) {
      if(state != 'close'){
        this.filter = {
          unique_id: "",
					redeem_code: "",
					member_id: "",
        }
        this.searchMember = ""

        if(state == 'reset'){
          this.redeemLogs = [];
        }else {
          this.fetchRedeemLog()
        }
      }else {
        this.dialog.filter = false
      }
    },

    async fetchMemberRedeem(){
			this.process.run = true;   
			await this.axios.get(`users/v1/company/list-member`,{
				params: {
          fullname: this.searchMember,
					limit: 20,
				}
			}).then((res) =>{
				this.memberList = res.data.data.list;
			}).finally(() => {
				this.process.run = false;
			});
		},

    async fethcDataRedeemLog() {
      if(this.filter.unique_id == "" && this.filter.redeem_code == "" && this.filter.member_id == "") {
        this.closeFilter('reset')
        this.dialog.filter = false
      }else {
        this.fetchRedeemLog();
      }
    },

    async fetchRedeemLog() {
      this.loading = true;
      await this.axios
        .get("/users/v1/company/search-prakerja-log", {
          params: {
            unique_id: this.filter.unique_id,
            redeem_code: this.filter.redeem_code,
            member_id: this.filter.member_id,
            limit: -1,
            // page: this.redeemLogs.page,
            // limit: this.redeemLogs.limit,
            // sort: this.redeemLogs.sort,
            // q: this.searching,
          },
        })
        .then((res) => {
          this.redeemLogs = res.data.data;
          // this.redeemLogs.total = res.data.total;
          this.loading = false;
          this.dialog.filter = false
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });
    },
    change(data) {
      this.redeemLogs.page = data;
    },
    parseDataRequestBody(dataBody) {
      return JSON.parse(dataBody);
    },
    detail(data) {
      this.redeemLogsDetail = data;
      this.dialogStatusPrakerja = true;
    },
    sorting(data) {
      this.redeemLogs.sortDesc = data;
    },
    voucher(data) {
      this.$router.push({ name: "voucher-detail", params: { id: data } });
    },
    filterExportData() {
      this.dialogExport = true;
    },
    closeFilterExport() {
      this.dialogExport = false;
      this.filter = {
        start_date: "",
        end_date: "",
        start_time: "",
        end_time: "",
      };
    },
    async exportData() {
      this.loadingExport = true;

      await this.axios
        .get("/users/v1/company/export-prakerja-log", {
          params: {
            limit: -1,
            sort: 'created_at',
            q: this.searching,
            start_date: `${this.filter.start_date}`,
            start_time: ` ${this.filter.start_time ? this.filter.start_time : '00:00'}`,
            end_date: `${this.filter.end_date}`,
            end_time: ` ${this.filter.end_time ? this.filter.end_time : '00:00'}`,
            range_date_type: 'created_at',
          }
        })
        .then((res) => {
          // const url = window.URL.createObjectURL(new Blob([res.data]));
          if(res.data.data.public_url) {
            const link = document.createElement("a");
            link.href = res.data.data.public_url;
            link.setAttribute("download", `${res.data.data.title}`);
            document.body.appendChild(link);
            link.click();
            this.loadingExport = false
            this.snackbar = {
              state: true,
              text: res.data.message,
            }
          }else {
            this.loadingExport = false
            this.snackbar = {
              state: true,
              text: "file tidak ditemukan",
            }
          }
        })
        .catch((err) => {
          this.loadingExport = false;
          this.snackbar = {
            state: true,
            text: err.response.data.message,
          }
        });
    },
  }
}
</script>