import axios from "axios";

export const list = (params = {}) => {
  return axios.get("/program/v1/company/public/list", { params });
};

export const listProgram = (params = {}) => {
  return axios.get("/program/v1/company/list", {
    params,
  });
};

export const addPrograms = (enable = [], disable = []) => {
  return axios.put("/program/v1/company/dashboard/add-program", { show_ids: enable, unshow_ids: disable }).then((res) => res.data);
};

export const deletePromgram = (id) => {
  return axios.delete(`/program/v1/company/dashboard/delete-program/${id}`);
};

export default { list, listProgram, addPrograms, deletePromgram };
