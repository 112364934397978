<template>
  <div class="px-1">
    <v-card outlined class="rounded-lg mb-5" v-if="!process.run">
      <v-card-text>
        <ValidationObserver ref="observer">
          <v-form>
            <p class="text-h6">Profile Trainer</p>
            <v-col>
              <p>Trainer Name</p>
              <ValidationProvider name="Fullname" rules="required" v-slot="{ errors }">
                <v-text-field
                  outlined
                  flat
                  v-model="form.fullname"
                  :error-messages="errors"
                  placeholder="Tuliskan disini"
                  color="primary">
                </v-text-field>
              </ValidationProvider>
            </v-col>
            <v-col>
              <ValidationProvider name="Email" rules="required|email" v-slot="{ errors }">
                <p>Trainer Email</p>
                <v-text-field
                  outlined
                  flat
                  disabled
                  v-model="form.email"
                  :error-messages="errors"
                  placeholder="Tuliskan disini"
                  hint="Email tidak dapat diubah"
                  persistent-hint
                  color="primary">
                </v-text-field>
              </ValidationProvider>
            </v-col>
            <v-col>
              <ValidationProvider name="Nomor Telepon" rules="required|numeric|min:10|max:15" v-slot="{ errors }">
                <p>Trainer Phone Number</p>
                <v-text-field
                  outlined
                  flat
                  type="number"
                  v-model="form.phone_number"
                  :error-messages="errors"
                  placeholder="Tuliskan disini"
                  color="primary">
                </v-text-field>
              </ValidationProvider>
            </v-col>
            <v-col>
              <p>Trainer Description</p>
              <v-textarea
                outlined
                flat
                v-model="form.description"
                placeholder="Tuliskan disini"
                rows="3"
                color="primary"
              ></v-textarea>
            </v-col>
            <v-col>
              <p class="mb-0">Gender</p>
              <v-radio-group row class="mt-2" hide-details="auto" v-model="form.gender.value">
                <v-radio color="primary" :value="1">
                  <template v-slot:label>
                    <div class="black--text">Laki - laki</div>
                  </template>
                </v-radio>
                <v-radio color="primary" :value="0">
                  <template v-slot:label>
                    <div class="black--text">Perempuan</div>
                  </template>
                </v-radio>
              </v-radio-group>
            </v-col>
            <v-col>
              <p class="mb-0">Trainer Status</p>
              <v-radio-group row class="mt-2" hide-details="auto" v-model="form.status">
                <v-radio color="primary" value="active">
                  <template v-slot:label>
                    <div class="black--text">Aktif</div>
                  </template>
                </v-radio>
                <v-radio color="primary" value="inactive">
                  <template v-slot:label>
                    <div class="black--text">Tidak aktif</div>
                  </template>
                </v-radio>
              </v-radio-group>
            </v-col>
            <!-- <v-col>
              <p>Program Trainer</p>


              <div v-if="programs.length > 0">
                <div v-for="(prog, i) in programs" :key="i">
                  <div class="d-flex py-2">
                    <v-icon
                      @click="deleteProgramTrainer(prog, i)"
                      color="red">
                      ri-delete-bin-line
                    </v-icon>
                    <v-card outlined class="pa-2 ml-3" style="flex: 1">
                      {{ prog.name }} {{ i+1 }}
                    </v-card>
                  </div>
                </div>
              </div>

              <div v-else class="">
                <v-alert type="warning" dense outlined class="subtitle-2">
                  Belum ada program yang ditambahkan pada trainer ini.
                </v-alert>
              </div>


            </v-col> -->
          </v-form>
        </ValidationObserver>
      </v-card-text>
      <v-card-actions class="pb-4 px-4">
        <v-spacer></v-spacer>
        <v-btn
          depressed
          width="120"
          height="40"
          color="grey lighten-3"
          class="text-capitalize"
          :loading="process.run"
          :to="`/trainer`"
        >
          Batal
        </v-btn>
        <v-btn
          depressed
          width="120"
          height="40"
          color="primary"
          class="text-capitalize"
          :loading="process.run"
          @click="save()"
        >
          Simpan
        </v-btn>
      </v-card-actions>
    </v-card>

    <v-snackbar top v-model="snackbar.state" :color="snackbar.color" outlined :timeout="snackbar.timeout">
      <div v-html="snackbar.text"></div>
      <template v-slot:action="{ attrs }">
        <v-btn small icon color="error" v-bind="attrs" @click="snackbar.state = false">
          <v-icon>$close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
export default {
  data() {
    return {
      programOptions: ["Program 1 ", "Program 2 ", "Program 3 ", "Program 4 "],
      breadcrumbs: [
        {
          text: "List Trainer",
          disabled: false,
          href: `/company/trainer-list/${this.$route.params.company_id}`,
        },
        {
          text: "Update Trainer",
          disabled: true,
          href: "#",
        },
      ],
      snackbar: {
        state: false,
        text: "",
        color: "",
        timeout: 4000
      },
      programs: [
        // {
        //   name: "Program",
        // },
        // {
        //   name: "Program",
        // },
        // {
        //   name: "Program",
        // },
        // {
        //   name: "Program",
        // },
      ],
      items: {},
      member: {},
      form: {},
      dialog: {
        attachment: false,
      },
      process: {
        run: false,
        state: "",
      },
    };
  },
  created() {
    this.getDetail();
  },
  mounted() {
    let title=  "Update Trainer"
    this.$emit("page-changed", 1, {
      title,
      link: '/trainer',
    });
  },
  methods: {
    removeProgram(index) {
      this.form.programs.splice(index, 1);
    },
    deleteProgramTrainer(item, index) {
      this.programs.splice(index, 1)
    },
    async getDetail() {
      let _self = this;
      _self.process.run = true;

      await this.axios.get(`trainer/v1/company/detail/${this.$route.params.id}`).then(
        (response) => {
          let res = response.data;
          let dataDetail = res.data
          dataDetail.status = dataDetail.status == '' ? 'inactive' : dataDetail.status
          this.form = dataDetail;
          this.process.run = false;
          this.$store.state.process.run = false;
        }
      );
    },
    async save() {

      const isValid = await this.$refs.observer.validate()

      if(isValid) {
        let data = {
          fullname: this.form.fullname,
          email: this.form.email,
          phone_number: this.form.phone_number,
          gender: this.form.gender.value,
          status: this.form.status,
          description: this.form.description,
          // additional_fields: {
          //   member_redeems: this.form.additional_fields.member_redeems
          //     ? this.form.additional_fields.member_redeems
          //     : [],
          //   members: this.form.additional_fields.members
          //     ? this.form.additional_fields.members
          //     : [],
          //   resellers: this.form.additional_fields.resellers
          //     ? this.form.additional_fields.resellers
          //     : [],
          // },
          // setting: {
          //   enable_notification: this.form.setting.enable_notification,
          //   one_time_update_profile: this.form.setting.one_time_update_profile,
          // },
          // programs: this.programOptions,
        };
        this.process.run = true;
        await this.axios.put(`trainer/v1/company/update/${this.$route.params.id}`, data)
          .then((response) => {
            let res = response.data;
            if (res.status) {
              this.snackbar = {
                state: true,
                text: `Update Trainer Succesfully`,
                color: "primary",
                timeout: 4000
              },
              setTimeout(() => {
                this.$router.push(`/trainer`,);
              }, 1000);
            } else {
              this.snackbar = {
                state: false,
                text: `Update Trainer Failed`,
                color: "red",
                timeout: 4000
              }
            }
            this.process.run = false;
          })
          .catch((e) => {
            this.process.run = false;
            this.snackbar = {
              state: false,
              text: e.response.data.message,
              color: "red",
              timeout: 4000
            }
          });
        // this.saveProgramOptions();
      }

    },
    data_attachment() {
      // console.log(data);
    },
    closeDialog () {
      this.dialog.attachment = false
    },
  },
};
</script>

<style>
.dropZone {
  width: 100%;
  min-height: 300px;
  height: auto;
  position: relative;
  border: 3px dashed #ddd;
}

.dropZone:hover {
  border: 2px solid #f05326;
}

.dropZone:hover .dropZone-title {
  color: #f05326;
}

.dropZone-info {
  color: #a8a8a8;
  position: absolute;
  top: 50%;
  width: 100%;
  transform: translate(0, -50%);
  text-align: center;
}

.dropZone-title {
  color: #787878;
}

.dropZone input {
  position: absolute;
  cursor: pointer;
  top: 0px;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}
</style>
