import Vue from "vue";
import VueRouter from "vue-router";
import Views from "../views";
// import Home from '../views/Home'
// import Unauth from '../views/unauth'
import store from "../store";

Vue.use(VueRouter);

// const QuestionBank = [
// ]

let routes = {
  auth: [
    { path: "/dashboard", name: "dashboard", component: Views.Dashboard },
    { path: "/program", name: "list-program", component: Views.Program.List },
    { path: "/program/users/:id", name: "list-user", component: Views.User.list },
    { path: "/program/tambah-program", name: "add-program", component: Views.Program.Form },
    { path: "/program/edit/:id", name: "edit-program", component: Views.Program.Detail },
    { path: "/update-password", name: "update-password", component: Views.Auth.UpdatePassword },

    // Kelola Dashboard
    { path: "/dashboard-settings", name: "dashboard-settings", component: Views.kelolaDashboard.Webinar.List },
    { path: "/program-webinar", name: "webinar", component: Views.kelolaDashboard.Webinar.List },
    {
      path: "/program-pembelajaran-mandiri",
      name: "pembelajaran-mandiri",
      component: Views.kelolaDashboard.PembelajaranMandiri.List,
    },

    // Prakerja
    { path: "/state-log", name: "list-state", component: Views.StateLog.List },
    { path: "/redeem-log", name: "list-redeem-log", component: Views.RedeemLog.List },

    { path: "/member-redeem", name: "list-member-redeem", component: Views.MemberRedeem.List },

    // Prakerja
    { path: "/state-log", name: "list-state", component: Views.StateLog.List },
    { path: "/redeem-log", name: "list-redeem-log", component: Views.RedeemLog.List },

    { path: "/program/:id", name: "program-detail", component: Views.Programs.Detail },

    // Export Queue
    { path: "/export-queue", name: "export-queue", component: Views.ExportQueue.List },

    { path: "/program/:id/create", name: "tna-create", component: Views.TnA.Create },
    // { path: '/activity/:id/add-act', name: 'tna-add', component: Views.TnA.AddAct },
    { path: "/program/:id/edit/:tnaId", name: "tna-edit", component: Views.TnA.Edit },

    // Activity
    { path: "/program/:program/:topic/create", name: "activity-create", component: Views.TnA.AddAct },
    { path: "/program/:program/:topic/:id/edit", name: "activity-edit", component: Views.TnA.Edit },

    // notification
    { path: "/notifikasi", name: "list-notifikasi", component: Views.Notifikasi.List },

    // trainer
    { path: "/trainer", name: "list-trainer", component: Views.Trainer.List },
    { path: "/trainer/program/:id", name: "list-program-trainer", component: Views.Trainer.ListProgram },
    { path: "/trainer/update/:id", name: "update-trainer", component: Views.Trainer.Update },
    { path: "/trainer/detail/:id", name: "detail-trainer", component: Views.Trainer.Detail },

    // import
    { path: "/import", name: "import-data", component: Views.Import.FormImport },
    { path: "/import-feedback", name: "import-data-feedback", component: Views.Import.FormImportFeedback },

    //Reseller
    { path: "/daftar-reseller", name: "daftar-reseller", component: Views.Reseller.list },
    { path: "/create-reseller", name: "create-reseller", component: Views.Reseller.create },
    { path: "/list-referal/:id", name: "list-referal", component: Views.Reseller.listUser },

    //certificate
    { path: "/certificate-participant/:id", name: "certificate-participant", component: Views.Certif.CertifPart },
    { path: "/certificate-graduation/:id", name: "certificate-graduation", component: Views.Certif.CertifGrad },

    //absensi
    { path: "/presensi", name: "presensi", component: Views.Presensi.list },
    // { path: '/update-content', name: 'update-content', component: Views.Manage.content },

    //page
    { path: "/privacy-policy", name: "privacy-policy", component: Views.SettingPage.PrivacyPolicy },
    { path: "/modal-siap-kerja", name: "modal-siap-kerja", component: Views.SettingPage.ModalSiapKerja },
  ],
  guest: [
    { path: "/", name: "login", component: Views.Auth.Login },
    { path: "/forgot-password", name: "forgot-password", component: Views.Auth.ForgotPassword },
    { path: "/password-reset", name: "password-reset", component: Views.Auth.PasswordReset },
    { path: "/password-changed", name: "password-changed", component: Views.Auth.PasswordChanged },
  ],
  default: [{ path: "*", name: "404 Not Found", component: Views.Errors.NotFound }],
};

routes.auth.forEach((route) => {
  route.meta = { auth: true };
});
routes.guest.forEach((route) => {
  route.meta = { guest: true };
});
routes = [...routes.auth, ...routes.guest, ...routes.default];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.path == "/" && store.getters.isLoggedIn) {
    return next("/dashboard");
  }
  if (to.meta.auth && !store.getters.isLoggedIn) {
    sessionStorage.setItem("ytc_redirect", location.pathname);
    return next("/");
  }
  if (to.meta.guest && store.getters.isLoggedIn) {
    return next("/dashboard");
  }
  return next();
});

export default router;
