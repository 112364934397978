var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"mb-2"},[_vm._v("Nama Fasilitator")]),_c('v-text-field',{attrs:{"filled":"","hide-details":"auto","rules":_vm.rules('Nama fasilitator', 'required').concat( [_vm.validate]),"placeholder":"Masukan nama fasilitator"},model:{value:(_vm.data.name),callback:function ($$v) {_vm.$set(_vm.data, "name", $$v)},expression:"data.name"}})],1),_c('v-col',[_c('div',{staticClass:"mb-2"},[_vm._v("Pilih Trainer")]),_c('v-autocomplete',{attrs:{"items":_vm.trainers,"multiple":"","item-text":"fullname","item-value":"id","dense":"","filled":"","placeholder":"Pilih Trainer"},scopedSlots:_vm._u([{key:"selection",fn:function(data){return [_c('v-chip',_vm._b({attrs:{"input-value":data.selected,"color":"primary","close":""},on:{"click":data.select,"click:close":function($event){return _vm.removeFasilitator(data.item)}}},'v-chip',data.attrs,false),[_c('div',[_vm._v(_vm._s(data.item.fullname))])])]}},{key:"item",fn:function(data){return [(typeof data.item !== 'object')?[_c('v-list-item-content',{domProps:{"textContent":_vm._s(data.item)}})]:[_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"innerHTML":_vm._s(data.item.fullname)}}),_c('v-list-item-subtitle',{domProps:{"innerHTML":_vm._s(data.item.email)}})],1)]]}}]),model:{value:(_vm.trainerIds),callback:function ($$v) {_vm.trainerIds=$$v},expression:"trainerIds"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"mb-2"},[_vm._v("Deskripsi")]),_c('editor',{attrs:{"api-key":"4paqvfu388didkn8yr5v662i655cn9x3g5w1usf7ucmypuoq","placeholder":"Masukan deskripsi","init":{
        height: 350,
        menubar: false,
        plugins: [
          'advlist autolink lists link charmap print preview anchor',
          'searchreplace visualblocks code fullscreen',
          'insertdatetime media table paste code help wordcount'
        ],
        toolbar:
          'undo redo | formatselect | bold italic backcolor | \
                                 alignleft aligncenter alignright alignjustify | \
                                 bullist numlist | link table',
        content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px;background:#f4f6f9; }'
      }},model:{value:(_vm.data.description),callback:function ($$v) {_vm.$set(_vm.data, "description", $$v)},expression:"data.description"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"mb-2"},[_vm._v("Foto")]),_c('div',{staticClass:"helper"}),_c('div',{staticClass:"drop display-inline text-center",on:{"dragover":function($event){$event.preventDefault();},"drop":_vm.onDrop2}},[(!_vm.data.picture)?_c('div',{staticClass:"content-img pt-12"},[_c('p',{staticClass:"mb-2 pt-8"},[_c('v-icon',{attrs:{"medium":""}},[_vm._v("ri-image-add-line")])],1),_c('p',{staticClass:"mb-0 subtitle-2"},[_vm._v("Tambahkan Gambar")]),_c('p',{staticClass:"mb-2 subtitle-2"},[_vm._v("dengan")]),_c('label',{staticClass:"btn-img display-inline text-center"},[_vm._v(" Pilih Berkas "),_c('input',{attrs:{"type":"file","name":"image2","accept":"image/*"},on:{"change":_vm.onChange2}})])]):_c('div',{staticClass:"hidden display-inline align-center py-4",class:{ 'image2': true }},[_c('div',{staticClass:"box-img-line ",staticStyle:{"width":"100%","margin":"auto","position":"relative"}},[_c('img',{staticClass:"img-cover",attrs:{"src":this.url || this.urlUp,"alt":""}}),_c('div',{staticClass:"btn-float"},[_c('label',{staticClass:"fab-white bg-label mx-2"},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" ri-arrow-go-back-line ")]),_c('input',{attrs:{"type":"file","name":"image","accept":"image/*"},on:{"change":_vm.onChange2}})],1)])])])])]),_c('v-snackbar',{attrs:{"top":"","color":"primary","outlined":"","timeout":_vm.timeout},scopedSlots:_vm._u([{key:"action",fn:function(ref){
      var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"small":"","icon":"","color":"error"},on:{"click":function($event){_vm.snackbar.state = false}}},'v-btn',attrs,false),[_c('v-icon',[_vm._v("$close")])],1)]}}]),model:{value:(_vm.snackbar.state),callback:function ($$v) {_vm.$set(_vm.snackbar, "state", $$v)},expression:"snackbar.state"}},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.snackbar.text)}})])]],2)}
var staticRenderFns = []

export { render, staticRenderFns }