<template>
  <div>
    <v-expand-transition>
      <div v-if="data.image">
        <v-img :src="data.image" contain max-height="200"></v-img>
      </div>
    </v-expand-transition>
    <v-text-field
      class="my-2"
      v-model="data.text"
      outlined
      filled
      color="primary"
      hide-details="auto"
      @input="inputText"
      :append-icon="fileExists || data.image ? '$delete' : 'ri-image-add-fill'"
      @click:append="handleClickAppend"
    />
    <input class="d-none" ref="inputFile" type="file" @change="setImage" accept="image/*" />
  </div>
</template>

<script>
import model from "../../_mixins/model";

export default {
  data: () => ({
    fileExists: false,
    imageSrc: "",
  }),
  mixins: [model],
  methods: {
    inputText(text) {
      if(this.data.image) {
        this.$set(this.data, 'error',  "Silahkah Pilih salah satu antara text atau gambar")
        
        setTimeout(() => {
          this.data.text = ""
        }, 10);
        setTimeout(() => {
          this.$set(this.data, 'error',  "")
        }, 3000);
      }else {
        this.data.answer = text
      }
    },
    handleClickAppend() {
      if(!this.data.text) {
        if (!this.fileExists && !this.data.image) this.selectImage();
        else this.unsetImage();
      }else {
        // console.log('here');
        this.$set(this.data, 'error',  "Silahkah Pilih salah satu antara text atau gambar")
        // this.data.error = "Silahkah Pilih salah satu antara text atau gambar"

        setTimeout(() => {
          this.$set(this.data, 'error',  "")
        }, 3000);
      }
    },
    selectImage() {
      this.$refs.inputFile.click();
    },
    unsetImage() {
      this.data.image = "";
      this.data.answer = ""
      this.fileExists = false;
      this.$delete(this.data, "file");
    },
    setImage(e) {
      const file = e.target.files;
      // this.data.file = file[0];
      this.setAttachment(file[0])
      // this.data.text = URL.createObjectURL(file[0]);
      // this.imageSrc = URL.createObjectURL(this.data.file);
      // this.$refs.inputFile.type = "text";
      // this.$refs.inputFile.type = "file";
      // this.$nextTick().then(() => {
      //   this.fileExists = true;
      // });
    },

    async setAttachment(file) {

      let formData = new FormData()
      formData.append('source', 'upload')
      formData.append('alt', 'Gambae')
      formData.append('caption', 'Gambar')
      formData.append('description', 'Gambar')
      formData.append('file', file)
      formData.append('title','Gambar')
      formData.append('url', '')
      this.$store
        .dispatch("media/create", formData)
        .then((res) => {
          this.data.image = res.data.public_url
          this.data.answer = res.data.public_url
          this.fileExists = true
        })
        .catch(() => {
          // console.log(error);
          this.saving = false;
        });
    },
  },
};
</script>
