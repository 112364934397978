require("./lib");

import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./lib/vuetify";
import CKEditor from "@ckeditor/ckeditor5-vue2";
import { abilitiesPlugin, Can } from "@casl/vue";
import appAbility from "./services/ability";
import { AbilityBuilder } from "@casl/ability";
import VeeValidate from './lib/VeeValidate'

Vue.use(CKEditor);
Vue.component("can", Can);
Vue.use(abilitiesPlugin, appAbility);
Vue.config.productionTip = false;

function updateAbility(user) {
    const { can, rules } = new AbilityBuilder();

    if (user.role === "superadmin") {
        can("manage", "all");
    } else {
        can("create", "Program");
    }

    appAbility.update(rules);
}
(async () => {
    const user = await store.dispatch("get_user");

    updateAbility({
        role: user?.role?.key || "superadmin"
    });

    new Vue({
        router,
        store,
        vuetify,
        VeeValidate,
        render: h => h(App)
    }).$mount("#app");
})();
