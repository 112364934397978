<template>
  <v-row>
    <v-col
      v-for="(option, index) in options"
      :key="index"
      cols="12"
      class="pb-0"
      style="position: relative"
      @mouseover="$set(option, 'showDelete', true)"
      @mouseleave="$set(option, 'showDelete', false)"
    >
      <v-card outlined :class="{ 'mr-16': !jigsaw }">
        <v-col>
          <v-row no-gutters>
            <v-text-field
              :disabled="readonly || jigsaw"
              background-color="white"
              :outlined="false"
              class="mr-4 mt-0 pt-0"
              v-model="option.text"
              @input="(text) => {
                option.answer = text
              }"
              placeholder="Masukkan opsi jawaban"
            ></v-text-field>
            <v-radio-group v-model="correct_answers" class="my-auto pt-0">
              <v-radio :disabled="readonly" :value="option.text"></v-radio>
            </v-radio-group>
          </v-row>
        </v-col>
        <div v-if="!jigsaw" class="col-md-8">
          <p class="mb-2">Attachment Image</p>
          <image-attachment-gameplay
            v-if="isMediaSet('image')"
            :disabled="isDisabled"
            class="d-inline-block"
            :src="option.image"
            @update="$set(option, 'image', $event);$set(option, 'answer', $event)"
            />
            <!-- @update="$set(option, 'image', [...$event.id])" -->
          <span v-if="isDisabled && _.isEmpty(option.image)">
            {{ "No image" }}
          </span>
        </div>
      </v-card>
      <v-btn v-if="!jigsaw && !readonly && option.showDelete" absolute icon top right @click="onDeleteOption(option, index)"
        ><v-icon>$delete</v-icon></v-btn
      >
    </v-col>
    <v-col v-if="!jigsaw" cols="12">
      <v-btn text @click="options.push({})">+ tambah opsi jawaban</v-btn>
    </v-col>
  </v-row>
</template>
<script>
import model from "../_mixins/model";
// import { VueEditor } from "vue2-editor";
import ImageAttachmentGameplay from "../../../_inc/modal/ImageAttachmentGameplay.vue";

export default {
  mixins: [model],
  props: {
    readonly: Boolean,
    jigsaw: Boolean,
  },
  components: {
    // VueEditor,
    ImageAttachmentGameplay,
  },
  data: () => ({
    // options: [],
    options: {
      text: "",
      media: [],
    },
    correct_answers: [],
    name_route: "",
    customToolbar: [
      [{ header: [false, 1, 2, 3, 4, 5, 6] }],
      ["bold", "italic", "underline", "strike"], // toggled buttons
      [{ align: "" }, { align: "center" }, { align: "right" }, { align: "justify" }],
      ["blockquote", "code-block"],
      [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
      [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
      [{ color: [] }, { background: [] }], // dropdown with defaults from theme
      ["clean", "image"], // remove formatting button
    ],
  }),
  watch: {
    options: {
      handler(val) {
        this.$set(this.data, "options", val);
      },
      deep: true,
    },

    correct_answers: {
      handler(val) {
        if (val) {
          this.$set(this.data, "correct_answers", [val]);
        }
      },
      deep: true,
    },
  },
  mounted() {
    // console.log("option", this.options);
    this.name_route = this.$route.name;
    // console.log('route',this.name_route)
  },
  computed: {
    isDisabled() {
      return !!(this.disabled || this.loading);
    },
  },
  methods: {
    isMediaSet() {
      // console.log('option',this.options)
      let disabled = this.disabled;
      let notset = this.options.image;
      let isset = this.options.image;
      return (!disabled && !notset) || isset;
      // return (
      //   !this.isDisabled &&
      //   !(this.question.question.with_media || this.question.question.media.url) ||
      //   ((this.question.question.with_media || this.question.question.media.url) &&

      // );
    },
    onDeleteOption(option, index) {
      // console.log(option);
      // console.log(index);
      // console.log(this.correct_answers);

      let isValid = this.correct_answers.includes(option.text)

      if (isValid) {
        this.correct_answers = ""
        this.$set(this.data, "correct_answers", []);
      }

      this.options.splice(index, 1)


    }
  },
  created() {
    this.options = this.data?.options ? this.data.options : [{}];
    this.correct_answers = this.data?.correct_answers ? this.data.correct_answers[0] : "";
  },
};
</script>
