<template>
  <v-col>
    <v-row>
      <v-col cols="12">
        <v-card outlined>
          <v-form ref="form" v-model="valid">
            <v-card-title class="pa-8 pb-2">Topik</v-card-title>
            <div class="px-8 pb-4">
              <v-row>
                <v-col cols="12">
                  <div class="font-weight-medium mb-2">Judul Topik</div>
                  <v-text-field
                    outlined
                    placeholder="Masukkan judul topik"
                    v-model="title_topik"
                    required
                    :rules="[...rules('Judul Topik', 'required'), validate]"
                    disabled
                  ></v-text-field>
                </v-col>
                <v-col cols="12" class="my-0 py-0" v-show="!isLoading">
                  <v-row>
                    <v-col cols="6" style="border-right: 1px solid grey;">
                      <div class="d-flex justify-space-between align-center">
                        <div>
                          <div class="d-flex justify-start font-weight-medium">
                            <div>Durasi Topik</div>
                            <v-tooltip right>
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                  v-on="on"
                                  v-bind="attrs"
                                  small
                                  right>
                                  ri-question-line
                                </v-icon>
                              </template>
                              <div style="font-size: 12px;">
                                Durasi topik digunakan untuk mengatur rentang waktu user dapat mengakses topik selanjutnya. <br/>
                                Dihitung sejak penyelesaian aktivitas terakhir pada topik ini.
                                <!-- Rentang waktu user dapat mengakses topik berikutnya. <br/>
                                Durasi topik digunakan untuk mengatur topik selanjutnya. <br/>
                                Dihitung sejak penyelesaian aktivitas terakhir pada topik ini. -->
                              </div>
                            </v-tooltip>
                          </div>
                          <div class="grey--text" style="font-size: 11px;">Pengaturan durasi topik dalam format hari</div>
                          <div v-show="duration" class="primary--text" style="font-size: 12px;">Pengaturan durasi topik selanjutnya diatur selama <span class="font-weight-medium" style="font-size: 14px !important;">{{ delay_topic_duration }}</span> hari</div>
                        </div>
                        <v-chip :color="duration ? 'green' : 'red'" outlined>
                          {{ duration ? 'Digunakan' : 'Tidak digunakan' }}
                        </v-chip>
                      </div>
                    </v-col>
                    <v-col cols="6">
                      <div class="d-flex justify-space-between align-center">
                        <div>
                          <div class="d-flex justify-start font-weight-medium">
                            <div>Verifikasi Wajah</div>
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                  v-on="on"
                                  v-bind="attrs"
                                  small
                                  right>
                                  ri-question-line
                                </v-icon>
                              </template>
                              <div style="font-size: 12px;">Verifikasi Wajah: Data wajah akan digunakan untuk mengonfirmasi keaslian identitas peserta</div>
                            </v-tooltip>
                          </div>
                          <div class="grey--text" style="font-size: 13px;">Verifikasi wajah untuk peserta kelas prakerja</div>
                        </div>
                        <v-chip :color="use_liveness ? 'green' : 'red'" outlined>
                          {{ use_liveness ? 'Digunakan' : 'Tidak digunakan' }}
                        </v-chip>
                      </div>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </div>
            <!-- <v-card-actions v-show="simpan" class="background">
              <v-spacer></v-spacer>
              <v-btn color="primary" :loading="submiting" @click="save();">Simpan</v-btn>
            </v-card-actions> -->
          </v-form>
        </v-card>
      </v-col>

      <activity-form
        v-show="aktifitas"
        v-for="(activity, index) in activities"
        :seq="index + 1"
        :key="activity.id"
        :activity="activity"
        :submiting="submiting"
        @click:submit="submit"
        @update:activity="$set(activities, index, $event)"
        @click:cancel="
          $router.push({ name: 'program-detail', params: { id: $route.params.program } })
        "
      ></activity-form>

      <!-- <v-col cols="12">
        <v-row no-gutters justify="space-between">
          <div>
            <v-btn color="primary" outlined @click="create">
              <span><v-icon>$add</v-icon> Tambah aktivitas</span>
            </v-btn>
          </div>
          <div>
            <v-btn
              class="mr-4"
              color="primary"
              outlined
              @click="$router.push({ name: 'program-detail' })"
              >Batal</v-btn
            >
            <v-btn color="primary">Simpan Topik</v-btn>
          </div>
        </v-row>
      </v-col> -->
      <v-snackbar top v-model="snackbar.state" color="primary" outlined :timeout="timeout">
        <div v-html="snackbar.text"></div>
        <template v-slot:action="{ attrs }">
          <v-btn small icon color="error" v-bind="attrs" @click="snackbar.state = false">
            <v-icon>$close</v-icon>
          </v-btn>
        </template>
      </v-snackbar>
    </v-row>
  </v-col>
</template>

<script>
import ActivityForm from "./ActivityForm";
import validationMixin from "../../_mixins/validation";

export default {
  mixins: [validationMixin],
  components: { ActivityForm },
  data: () => ({
    activities: [],
    aktifitas: true,
    simpan: true,
    title_topik: "",
    delay_topic_duration: "",
    duration: false,
    use_liveness: false,
    submiting: false,
    valid: true,
    errors: null,
    isDisabled: false,
    snackbar: {
      state: false,
      text: "",
    },
    timeout: 5000,
  }),
  computed: {
    validate() {
      return !this.errors || this.errors.message;
    },
  },
  created() {
    this.$emit("page-changed", 1, {
      title: "Topik & Aktifitas",
      link: `/program/${this.$route.params.program}`,
    });
    this.$emit("page-changed", 2, {
      title: "Tambah Topik & Aktivitas",
      link: this.$route.path,
    });
    this.create();
    this.detail();
  },
  methods: {
    async submit(payload) {
      if (payload instanceof Error) {
        // console.error(payload);
        let message = payload.message;
        if (payload.response?.data?.message) {
          message = payload.response.data.message;
        }
        this.snackbar.text = `Error: ${message}.`;
        this.snackbar.state = true;
        return;
      }

      this.submiting = true;
      const program = this.$route.params.program;
      const topic = this.$route.params.topic;
      try {
        const data = await this.$store
          .dispatch("activity/create", { program, topic, ...payload })
          .then((res) => res.data);
        this.snackbar.text = `Aktivitas berhasil dibuat.`;
        this.snackbar.state = true;
        setTimeout(() => {
          if (["pre_test", "quiz", "post_test"].includes(data.type)) {
            this.$router.push({
              name: "activity-edit",
              params: { program, topic, id: data.id },
            });
          } else {
            this.$router.push({ name: "program-detail", params: { id: program } });
          }
          this.submiting = false;
        }, 1000);
      } catch (error) {
        let message = error.message;
        if (error.response?.data) {
          message = error.response.data.message;
        }
        this.snackbar.text = `Error: ${message}`;
        this.snackbar.state = true;
        this.submiting = false;
        console.error(error);
      }
    },
    async detail() {
      this.isLoading = true
      await this.axios.get(`/topic/v1/detail/${this.$route.params.topic}`).then((response) => {
        let res = response.data
        this.title_topik = res.data.name;
        this.delay_topic_duration = res.data.setting.use_delay_next_topic;
        this.duration = res.data.setting.use_delay_next_topic ? true : false;
        this.use_liveness = res.data.setting.use_liveness;
        this.isLoading = false
      });
    },
    create() {
      this.$emit("page-changed", 1, {
        title: "Topik & Aktifitas",
        link: `/program/${this.$route.params.program}`,
      });
      this.$emit("page-changed", 2, {
        title: "Tambah Topik & Aktivitas",
        link: this.$route.path,
      });
      this.activities.push({status: 'publish', 
      settings: {
        max_upload_duration_evaluasi_praktek_mandiri:  7,
        is_show_video_control: 0
      }, theory: {
        file_variants: [],
      }});
    },
    onDragStart(e, index) {
      e.dataTransfer.setData("index", index);
    },
    onDrag(e) {
      if (!e.clientY) return;
      if (e.clientY < 100) {
        window.scrollTo({
          top: window.pageYOffset - 100,
          behavior: "smooth",
        });
      }
      if (e.clientY > window.outerHeight - 100) {
        window.scrollTo({
          top: window.pageYOffset + 100,
          behavior: "smooth",
        });
      }
    },
    onDrop(e, position) {
      const index = e.dataTransfer.getData("index");
      const activity = this.activities.splice(index, 1);
      this.activities.splice(position, 0, ...activity);
    },
    save() {
      this.errors = null;
      this.submiting = true;

      setTimeout(() => {
        if (this.$refs.form.validate()) {
          let params = {
            name: this.title_topik,
            status: "draft",
            program_id: this.$route.params.id,
          };
          this.axios
            .post(`topic/v1/create`, params)
            .then(() => {
              this.submiting = false;
              this.snackbar.text = `Topik berhasil ditambahkan`;
              this.snackbar.state = true;
              this.aktifitas = true;
              this.simpan = false;
              this.isDisabled = true;
            })
            .catch((res) => {
              this.submiting = false;
              if (res.response) {
                this.snackbar.text = res.response.data.message;
                this.snackbar.state = true;
              }
            });
        }
      }, 50);
    },
  },
};
</script>
