var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"white pa-4 rounded-lg"},[_c('form',{ref:"form"},[_c('v-row',[_c('v-col',{attrs:{"id":"search","cols":"12"}},[_c('div',{staticClass:"mb-2"},[_vm._v("Title")]),_c('v-text-field',{attrs:{"placeholder":"Title","outlined":"","color":"primary"},model:{value:(_vm.title),callback:function ($$v) {_vm.title=$$v},expression:"title"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"mb-2"},[_vm._v("Konten")]),_c('editor',{attrs:{"api-key":"4paqvfu388didkn8yr5v662i655cn9x3g5w1usf7ucmypuoq","placeholder":"Masukan privacy policy","init":{
            height: 350,
            menubar: false,
            plugins: [
              'advlist autolink lists link image charmap print preview anchor',
              'searchreplace visualblocks code fullscreen',
              'insertdatetime media table paste code help wordcount'
            ],
            toolbar:
              'undo redo | formatselect | fontsize | bold italic | forecolor backcolor | alignleft aligncenter alignright alignjustify | outdent indent | bullist numlist | image link table',
            image_uploadtab: false,
            content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px;background:#f4f6f9; }'
          }},model:{value:(_vm.content),callback:function ($$v) {_vm.content=$$v},expression:"content"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('div',[_vm._v("Status")]),_c('v-switch',{attrs:{"label":_vm.is_status ? 'Aktif' : 'Tidak aktif'},model:{value:(_vm.is_status),callback:function ($$v) {_vm.is_status=$$v},expression:"is_status"}})],1)],1),_c('v-card-actions',{staticClass:"pb-2 px-7"},[_c('v-spacer'),_c('v-btn',{staticClass:"text-capitalize white--text",attrs:{"width":"100","elevation":"0","color":"primary","loading":_vm.process.run,"disabled":_vm.process.run},on:{"click":function($event){return _vm.save()}}},[_vm._v(" Simpan ")])],1)],1),_c('v-snackbar',{attrs:{"color":_vm.snackbar.color,"top":"","outlined":"","timeout":5000},scopedSlots:_vm._u([{key:"action",fn:function(ref){
          var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"small":"","icon":"","color":_vm.snackbar.color},on:{"click":function($event){_vm.snackbar.state = false}}},'v-btn',attrs,false),[_c('v-icon',[_vm._v("$close")])],1)]}}]),model:{value:(_vm.snackbar.state),callback:function ($$v) {_vm.$set(_vm.snackbar, "state", $$v)},expression:"snackbar.state"}},[_c('div',{staticClass:"text-capitalize",domProps:{"innerHTML":_vm._s(_vm.snackbar.text)}})])],1)}
var staticRenderFns = []

export { render, staticRenderFns }