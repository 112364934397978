import axios from "axios";
import url from "./api";

const config = { headers: { "content-type": "application/json" } };

const state = () => ({
  data: {},
  params: {},
  dataProgram: {},
  paramsProgram: {},
});

const getters = {
  data: (state) => state.data,
  dataProgram: (state) => state.data,
  params: (state) => state.params,
  list: (state) => state.data.list || [],
  limit: (state) => state.data.limit || 5,
  page: (state) => state.data.page || 1,
  total: (state) => state.data.total || "0",
  paramsProgram: (state) => state.paramsProgram,
  listProgram: (state) => state.data.listProgram || [],
  limitProgram: (state) => state.data.limitProgram || 5,
  pageProgram: (state) => state.data.pageProgram || 1,
  totalProgram: (state) => state.data.totalProgram || "0",
  detail: (state) => state.data.detail || {},
};

const mutations = {
  SET_DATA: (state, data) => {
    state.data = { ...state.data, ...data };
  },
  SET_PARAMS(state, data) {
    state.params = data;
  },
  SET_DATA_PROGRAM: (state, data) => {
    state.dataProgram = { ...state.dataProgram, ...data };
  },
  SET_PARAMS_PROGRAM(state, data) {
    state.paramsProgram = data;
  },
  SET_DETAIL: (state, data) => {
    state.data = { ...state.data, ...{ detail: data } };
  },
};

const actions = {
  // async list({ commit, getters }, params) {
  async list({ state, commit }, params ) {
    let a ={
        page: params.q != "" ? 1 : state.data.page, limit: state.data.limit, sort: 'created_at', sort_type: 'desc'
      }
      params = {
        ...a,
        ...state.params,
        ...params,
      };
      // console.log('params umum', params)
      state.data.list = [];
      return axios.get(url.trainer_list,{ params })
        .then((res) => res.data)
        .then((res) => {
          delete params.page;
          delete params.limit;
          commit("SET_PARAMS", params);
          commit("SET_DATA", res.data);
          return res.data;
        });
  },   
  async list_program({ state, commit }, params ) {
    let a ={
        page: state.data.page, limit: state.data.limit, sort: 'created_at', sort_type: 'desc'
      }
      params = {
        ...a,
        ...state.params,
        ...params,
      };
      // console.log('params umum', params)
      state.data.list = [];
      return axios.get(url.trainer_program_list,{ params })
        .then((res) => res.data)
        .then((res) => {
          delete params.page;
          delete params.limit;
          commit("SET_PARAMS_PROGRAM", params);
          commit("SET_DATA_PROGRAM", res.data);
          return res.data;
        });
  },   
  async show(context, payload) {
    return await axios.get(`${url.trainer_show(payload)}`).then((res) => res.data);
  },
  async create({ dispatch }, data) {
    return axios.post(url.trainer_create, data).then(dispatch("list"));
  },
  async update({ dispatch }, data) {
    data = {
      id: data.id,
      category_id: data.category_id || data.category.id,
      name: data.name,
      available_part: data.available_part,
    };

    return axios
      .post(url.trainer_update.replace("{id}", data.id), data, config)
      .then(dispatch("list"));
  },
  async delete({ dispatch }, id) {
    // console.log(id);
    return axios
      .delete(url.trainer_delete(id))
      .then(dispatch("list"));
  },
};

export default { namespaced: true, state, getters, mutations, actions };
