export default [
  { title: "Dashboard", icon: "$dashboard", link: "/dashboard" , permission: 'Dashboard'},
  { title: "Program", icon: "$program", link: "/program", permission: 'Program', children: [
    { title: "Daftar Program", icon: "$program", link: "/program", permission: 'Program' },
    { title: "Member Redeem", icon: "$program", link: "/member-redeem", permission: 'Program' },
    // { title: "Antrian Export", icon: "$program", link: "/export-queue", permission: 'Program' },
  ]},
  { title: "Kelola Dashboard", icon: "ri-settings-3-line", link: "/dashboard-settings" , permission: 'Prakerja'},
  // tidak jadi, hapus aja kalau udah ready to prod
  // { title: "Kelola Dashboard", icon: "ri-settings-3-line", link: "/", permission: 'Prakerja', children: [
  //   { title: "Webinar", icon: "ri-settings-3-line", link: "/program-webinar", permission: 'Webinar' },
  //   { title: "Pembelajaran Mandiri", icon: "ri-settings-3-line", link: "/program-pembelajaran-mandiri", permission: 'PembelajaranMandiri' },
  // ]},
  { title: "Trainer", icon: "ri-team-line", link: "/trainer", permission: 'Trainer' },
  { title: "Reseller", icon: "ri-user-line", link: "/daftar-reseller", permission: 'Reseller' },
  { title: "Notifikasi", icon: "ri-chat-settings-line", link: "/notifikasi", permission: 'Notification' },
  { title: "Presensi", icon: "ri-list-check-2", link: "/presensi", permission: 'Presensi' },
  { title: "Import Nilai", icon: "ri-upload-cloud-2-line", link: "/presensi", permission: 'Presensi', children: [
    { title: "UK", icon: "ri-upload-cloud-2-line", link: "/import", permission: 'Import' },
    { title: "TPM", icon: "ri-upload-cloud-2-line", link: "/import-feedback", permission: 'Import' },
  ]},
  { title: "Halaman", icon: "ri-file-list-3-line", link: "/", permission: 'Page', children: [
    { title: "Privacy Policy", icon: "ri-file-list-3-line", link: "/privacy-policy" },
    { title: "Modal Siap Kerja", icon: "ri-file-list-3-line", link: "/modal-siap-kerja" },
    // { title: "Halaman", icon: "ri-file-list-3-line", link: "/halaman" },
  ]},
  { title: "Prakerja", icon: "ri-settings-3-line", link: "/", permission: 'Prakerja', children: [
    { title: "Log State", icon: "ri-settings-3-line", link: "/state-log", permission: 'State' },
    { title: "Log Redeem", icon: "ri-settings-3-line", link: "/redeem-log", permission: 'Logs' },
  ]},
  // { title: "Sertifikat", icon: "ri-award-fill", link: "/update-content" },
];
