<template>
  <v-row>
    <v-col v-for="(question, index) in questions" :key="question.id" cols="12">
      <div class="rounded pa-4" style="border: 1px solid #9db0c6;">
        <div class="d-flex justify-space-between align-center">
          <h3>Nomor {{ index + 1 }}</h3>
          <v-btn icon @click.stop="deleteQuestion(question, index)">
            <v-icon> $delete </v-icon>
          </v-btn>
        </div>

        <input-soal v-model="questions[index]" jigsaw />

        <v-alert v-show="question.errorData" type="error" text outlined>
          <div style="white-space: pre;">{{ question.errorData }}</div>
        </v-alert>

        <!-- <v-alert type="info" text outlined dense>
          <div style="font-size: 12px;">
            Jangan lupa klik <strong>Simpan Soal</strong> setelah selesai mengisi data dengan lengkap.
          </div>
        </v-alert> -->

        <div class="text-right">
          <v-btn color="primary" type="button" :loading="isLoading" class="white--text text-right" @click="savedGameplays(question, index)">
            Simpan Soal
          </v-btn>
        </div>
      </div>
    </v-col>

    <v-col cols="12">
      <v-btn :disabled="questions.length != gameplays.length" color="primary" outlined @click="addQuestion">
        Tambah Soal
      </v-btn>
    </v-col>

    <v-dialog v-model="delete_question.show" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span> <v-icon>$warning</v-icon> Hapus Soal </span>
        </v-card-title>
        <v-divider></v-divider>
        <div>
          <v-card-text>
            Apakah anda ingin menghapus soal ini?
          </v-card-text>
        </div>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn :disabled="delete_question.deleting" outlined color="primary" large @click="cancelDeleteQuestion()">
            Batal
          </v-btn>
          <v-btn
            :loading="delete_question.deleting"
            class="white--text"
            color="primary"
            large
            @click="confirmDeleteQuestion()"
          >
            Hapus
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
const blankQuestion = {
  id: "",
  type: "",
  question: { text: "", image: "", audio:"" },
  point: null,
  options: [{ text: "Benar",  image: "", audio: "" },{ text: "Salah",  image: "", audio: "" }],
  correct_answers: [],
  errorData: "",
  explanation: {
    text: "",
    image: "",
    audio: ""
    // correct: { text: "", media: [] },
    // wrong: { text: "", media: [] },
  },
};

import InputSoal from "../../Contents/SingleQuestionGameplay.vue";
import model from "../../_mixins/model";

export default {
  components: { InputSoal },
  mixins: [model],
  data: () => ({
    delete_question: { show: false, index: null, id: "" },
    snackbar: {
      state: false,
      text: "",
    },
    isSaveLastData: false,
    isLoading: null,
    timeout: 5000,
    questions: [],
    questionError: [],
    gameplays: []
  }),
  created() {
    this.fetchDetailGameplay();
  },
  methods: {
    async fetchDetailGameplay() {
      // const this.data.gameplays.toString()IDS = this.data.gameplays.toString()

      // console.log('IDS',IDS);

      
      const {data: detailQuiz} = await this.$store.dispatch("gameplay/show_multi", {
        ids: `${this.data.gameplays.toString()}`
      })
      
      this.questions = detailQuiz
      this.gameplays = this.data.gameplays
    },
    addQuestion() {
      this.questions.push({ ...JSON.parse(JSON.stringify(blankQuestion)), type: "jigsaw" });
      this.snackbar.text = ""
    },
    deleteQuestion(data, index) {
      this.delete_question.show = true;
      this.delete_question.index = index;
      this.delete_question.id = data.id;
      this.snackbar.text = ""
    },
    cancelDeleteQuestion() {
      this.delete_question.show = false;
      this.delete_question.index = null;
      this.delete_question.index = "";
      this.snackbar.text = ""
    },
    confirmDeleteQuestion() {
      let questions = [...this.questions]
      questions.splice(this.delete_question.index, 1);

      // DELETE DATA GAMEPLAY ID
      let indexData = this.gameplays.findIndex((item) => this.delete_question.id  == item);
      if (indexData != -1) {
        this.gameplays.splice(indexData, 1)        
      }


      this.delete_question.show = false;
      this.delete_question.index = null;
      this.delete_question.id = "";
      this.snackbar.text = ""

      this.$emit('click:submit',{
        type: "jigsaw",
        gameplays: this.gameplays,
        saving: false
      })

      // console.log(questions);

      // this.questions = questions

      this.fetchDetailGameplay()
    },
    validate(key) {
      var isValid = true;

      // for (let i = 0; i < this.questions.length; i++) {
      // }
      const question = this.questions[key]; // or question
      question.errorData = ""

      if (!question.question.text) {
        isValid = false
        question.errorData += "Pertanyaan tidak boleh kosong \n"
      }

      if (!(question.options && question.options.length)) {
        isValid = false
        question.errorData += "Opsi jawaban tidak boleh kosong \n"
      }

      if (!(question.correct_answers && question.correct_answers.length)) {
        isValid = false
        question.errorData += "Jawaban benar tidak boleh kosong \n"
      }

      if (!question.point) {
        isValid = false
        question.errorData += "Poin tidak boleh kosong atau 0 \n"
      }

      setTimeout(() => {
        question.errorData = ""
      }, 3000);
      return isValid;
    },

    async saveGameplay(data) {
      // const headers = { "content-type": "application/json" };
      if (data.id) {
        let payload = {
          id: data.id,
          data: data
        }
        return await this.$store.dispatch(`gameplay/edit`, payload);
      } else {
        return await this.$store.dispatch(`gameplay/create`, data);
      }
    },

    async savedGameplays(question, index) {

      this.isLoading = true
      
      let isValid = this.validate(index)
      
      if (isValid) {
        try {
          await this.saveGameplay(question)
            .then((res) => {
              let response = res.data
  
              this.questions[index].id = response.id
  
              let gametrue = this.gameplays.includes(response.id)
  
              if(!gametrue){
                this.gameplays.push(response.id);
              }
  
              // console.log('gameplays',this.gameplays);
              this.$emit('click:submit',{
                type: "jigsaw",
                gameplays: this.gameplays,
                saving: true
              })
  
              this.isLoading = false;
            }).catch(async (error) => {
              this.isLoading = false;
              this.snackbar.text = error.message
              throw error;
              // reject(error);
            });
        } catch (error) {
          this.isLoading = false;
          throw error;
        }
      }else {
        this.isLoading = false
      }

    },
  },
};
</script>
