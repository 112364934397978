<template>
  <v-row justify="center">
    
    <v-col v-if="!isPaketEmpty" cols="4" class="pt-5">
      <v-row class="flex-column" align-content="center">
        <v-col class="mx-auto">
          <v-img src="@/assets/images/data_empty.png" width="200" class="text-center mx-auto"/>
        </v-col>
        <v-col>
          <div class="title text-center">Tidak ada reseller</div>
        </v-col>
        <div class="subtitle body-1 text-center">
          klik tombol di bawah ini untuk menambahkan reseller baru
        </div>
        <v-col class="text-center">
          <v-btn color="primary" elevation="0" :to="{ name: 'create-reseller'}">
            <v-icon>ri-add-line</v-icon> Reseller baru
          </v-btn>
        </v-col>
      </v-row>
    </v-col>

    <v-col v-else>
      <v-row>
      <v-col id="search" lg="6" class="d-flex align-center">                  
        <v-text-field
          outlined
          append-icon="$search"
          class="pt-0 mt-0 mr-4"
          placeholder="Search"
          v-model="name"
          solo
          flat
          dense
          @keydown.enter="fetchPeserta"
        >
          <template v-slot:append v-if="name">
            <v-icon @click="name = '';fetchPeserta()">ri-close-line</v-icon>
          </template>    
        </v-text-field>   
        <v-btn
					small
					color="primary mr-3"
					elevation="0"
					@click="fetchPeserta">
					Search
				</v-btn>       
      </v-col>
      <v-col lg="6" class="d-flex align-end justify-end">
        <v-btn
            color="primary"
            elevation="0"
            small
            class="mr-2"
            @click="dialog=true;listReseller()"
          >
            <v-icon class="mr-2">ri-download-line </v-icon> Export            
          </v-btn>
        <v-btn
          class="white--text"
          color="primary"
          small
          :to="{ name: 'create-reseller'}"
        >
          <v-icon class="mr-2">ri-add-line </v-icon> Reseller Baru
        </v-btn>
      </v-col>
    </v-row>
      <v-row>
        <v-col id="dt-program">          
          <reseller-list
              :loading="table_loading"
              :data="data"
              @sorting="sorting"
              @change="change"
              @refresh-list="fetchPeserta"
            />            
        </v-col>
      </v-row>
      <v-dialog v-model="is_change.show" max-width="600px" persistent>
        <v-card>
          <v-card-title>
            <span>  Update Reseller </span>
          </v-card-title>
          <v-divider></v-divider>
          <div>
            <v-card-text>
              <v-form
                class=""
              >
                <v-col cols="12">
                  <div class="mb-2">Nama Lengkap</div>
                  <v-text-field required filled hide-details="auto" v-model="seller.fullname" placeholder="Masukan Nama Lengkap" class="border-0"></v-text-field>
                </v-col>
                <v-col cols="12">
                  <div class="mb-2">Domisili</div>
                  <v-text-field required filled hide-details="auto" v-model="meta.domicile"  placeholder="Masukan Domisili" class="border-0"></v-text-field>
                </v-col>
                <v-col cols="12">
                  <div class="mb-2">No WA</div>
                  <v-text-field required filled hide-details="auto" v-model="meta.whatsapp_number" placeholder="Masukan No WA" class="border-0"></v-text-field>
                </v-col>
                <v-col cols="12">
                  <div class="mb-2">Provider Komisi</div>
                  <v-text-field required filled hide-details="auto" v-model="meta.commission_provider" placeholder="Masukan Provider Komisi" class="border-0"></v-text-field>
                </v-col>
                <v-col cols="12">
                  <div class="mb-2">No Handphone</div>
                  <v-text-field required filled hide-details="auto" v-model="seller.phone" placeholder="Masukan No Handphone" class="border-0"></v-text-field>
                </v-col>
                <v-col cols="12">
                  <div class="mb-2">Email</div>
                  <v-text-field
                    class=""
                    v-model="seller.email"
                    placeholder="Masukkan Email"
                    required
                    outlined
                    hide-details="auto"
                    readonly
                  ></v-text-field>
                </v-col>
                <!-- <v-col cols="12">
                  <div class="mb-2">Password</div>
                  <v-text-field
                    filled
                    
                    placeholder="Masukkan password"
                    :type="show ? 'text' : 'password'"
                    :append-icon="show ? 'ri-eye-fill' : 'ri-eye-off-fill'"
                    @click:append="show = !show"
                    outlined
                    hide-details="auto"
                    
                  ></v-text-field>          
                </v-col> -->
              </v-form>  
            </v-card-text>            
          </div>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn outlined color="primary" small elevation="0" @click="is_change.show=false">
              Batal
            </v-btn>
            <v-btn
              class="white--text"
              color="primary"
              small
              elevation="0"
              :loading="submitting"
              @click="saveUpdate(seller.id)"
            >
              Ya, Ubah!
            </v-btn>            
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialog" max-width="600px" persistent>
        <v-card>
          <v-card-title>
            <span>  Export </span>
          </v-card-title>
          <v-divider></v-divider>
          <div>
            <v-card-text>
              <v-col cols="12">
                <div class="mb-2">Daftar Reseller</div>
                <v-autocomplete
                  v-model="list_referral"
                  :items="list_reseller"
                  :item-text="'fullname'"
                  :item-value="'referral.code'"
                  dense
                  filled
                  multiple
                  chips
                >
                  <template v-slot:item="{ active, item, attrs, on }">
                    <v-list-item v-on="on" v-bind="attrs" #default="{ active }">
                      <v-list-item-action>
                        <v-checkbox :input-value="active"></v-checkbox>
                      </v-list-item-action>
                      <v-list-item-content>                        
                        <v-list-item-title v-html="item.fullname"></v-list-item-title>
                        <v-list-item-subtitle v-html="item.referral.code"></v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col id="progres-icon" cols="12">
                <v-row>
                  <v-col cols="5">
                    <div class="mb-2">Tanggal Mulai</div>
                    <v-menu
                      ref="menu"
                      v-model="menu"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="date"
                          append-icon="ri-calendar-line"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          @click:clear="date = null"
                          clearable
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="date"
                        :active-picker.sync="activePicker"
                        :max="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)"
                        min="1950-01-01"
                        @change="save"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="5">
                    <div class="mb-2">Tanggal Akhir</div>
                    <v-menu
                      ref="menu2"
                      v-model="menu2"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="date2"
                          append-icon="ri-calendar-line"                          
                          v-bind="attrs"
                          v-on="on"
                          @click:clear="date2 = null"
                          clearable
                          readonly
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="date2"
                        :active-picker.sync="activePicker2"
                        :max="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)"
                        min="1950-01-01"
                        @change="save2"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="2" class="pl-0">
                    <div class="mb-2 white--text">Tanggal</div>                    
                    <v-btn color="primary" elevation="0" @click="resetTgl()">
                      Reset
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>                                            
            </v-card-text>            
          </div>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn outlined color="primary" small elevation="0" @click="dialog=false">
              Batal
            </v-btn>
            <v-btn
              class="white--text"
              color="primary"
              small
              elevation="0"
              :loading="submitting"
              @click="download"
            >
              Download
            </v-btn>            
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-snackbar 
        top 
        v-model="snackbar.state"
        color="primary"
        outlined
        :timeout="timeout"
      >
        <div v-html="snackbar.text"></div>
        <template v-slot:action="{ attrs }">
          <v-btn
            small
            icon
            color="error"
            v-bind="attrs"
            @click="snackbar.state = false"
          >
            <v-icon>$close</v-icon>
          </v-btn>
        </template>
      </v-snackbar>
    </v-col>
  </v-row>
</template>

<script>
import ResellerList from "./components/table";
import { mapGetters} from 'vuex'
// import moment from "moment";


export default {
  name: "user-list",
  components: { ResellerList},
  data: () => ({
    table_loading: false,    
    loading:false,
    submitting:false,
    name:'',
    show:true,
    is_change: {
      show:false,
      id:''
    },
    snackbar: {
        state: false,
        text: "",
      },
    timeout:5000,
    reseller:{},
    seller:{},
    meta:{},
    idSeller:'',
    dir:'',

    dialog:false,
    url_:'',
    activePicker: null,
    date: null,
    menu: false,
    activePicker2: null,
    date2: null,
    menu2: false,
    list_reseller:[],
    list_referral:[]
  }),
  computed: {
    isPaketEmpty: () => true,
    // ...mapGetters("user", ["list", "page", "limit", "total"]),
    ...mapGetters({ data: "reseller/data", params: "reseller/params" }),
    // topics() {
    //   return this.topics || [];
    // },
  },
  mounted() {
    this.$emit("page-changed", 0, { title: "Reseller", link: `/daftar-reseller` });
    // this.$emit("page-changed", 1, {
    //   title: "Daftar",
    //   link: this.$route.path,
    // });
    this.fetchPeserta();    
  },
  created(){
  },
  watch: {
      menu (val) {
        val && setTimeout(() => (this.activePicker = 'YEAR'))
      },
      menu2 (val) {
        val && setTimeout(() => (this.activePicker2 = 'YEAR'))
      },
      progress_start(newVal){
      if(newVal === '') {
        this.progress_start = null;
      }
    },
    progress_end(newVal){
      if(newVal === '') {
        this.progress_end = null;
      }
    },
  },
  methods: {
    save (date) {
      this.$refs.menu.save(date)
    },
    save2 (date2) {
      this.$refs.menu2.save(date2)
    },
    resetTgl(){
      this.date=null;
      this.date2=null;
    },  
    fetchAll(){
      this.fetchPeserta();
    },
    async fetchPeserta() {
      this.table_loading = true;
      // let user = {
      //   id:this.$route.params.id,
      //   q: this.name_program
      // }
      // let q= this.name_program      
      this.$store.dispatch("reseller/list",{q: this.name}).finally(() => {
        this.table_loading = false;
      });
      
    },
    sorting(value) {
      if (value === true) {
        this.dir='desc'
      }else{this.dir='asc'}
      this.table_loading = true;    
      this.$store.dispatch("reseller/list",{q: this.name,sort:'referral.total_user',dir:this.dir}).finally(() => {
        this.table_loading = false;
      });
    },
    fetchSearch(value) {
      this.table_loading = true;
      this.$store.dispatch("reseller/list",this.$route.params.id,{
        q: value,page:1}).finally(() => {
        // console.log('q hasil',this.name)      
        this.table_loading = false;
      });          
    },
    change(member) {
      this.is_change.show=true;
      this.is_change.id=member;
      this.getDetail(member);
    },
    getDetail(id){
      this.idSeller=id;
      this.axios
        .get(`reseller/v1/detail/${this.idSeller}`)
        .then((res) =>{
          this.seller = res.data.data;
          this.meta = this.seller.meta || {};
        })
    },
    saveUpdate(id){
      this.submitting=true;

      const data = {
        fullname: this.seller.fullname,
        phone: this.seller.phone,
        email: this.seller.email,
        status: "active",
        meta:{
          domicile: this.meta.domicile,
          whatsapp_number:this.meta.whatsapp_number,
          commission_provider:this.meta.commission_provider
        }
      }
        this.axios
          .put(`reseller/v1/update/${id}`, data)
          .then(() =>{
            this.submitting=false;
            this.is_change.show=false;
            this.fetchPeserta();
              this.snackbar.text = `Successfully update data reseller`;
              this.snackbar.state = true;              
          })
          .catch((res) => {
            this.submitting=false;          
            if (res.response) {
              this.snackbar.text = res.response.data.message;
              this.snackbar.state = true;
            }
          });
    },
    listReseller() {
      this.axios
        .get("/reseller/v1/list?page=1&limit=-1")
        .then((response) => {
          let res = response.data.data.list;
          this.list_reseller = res;
        });
    },
    forceFileDownload(url){
      // const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', 'file.png') //or any other extension
      document.body.appendChild(link)
      link.click()
    },
    download(){
      // var a = moment(this.date);
      // var b = moment(this.date2);
      // let ab=b.diff(a, 'days');
      // if (ab >= 32 ) {
      //   this.snackbar.state = true;        
      //   this.snackbar.text = 'Periode yang anda pilih lebih dari 31 hari';
      // }
      // else{
        // console.log('sukses')
        this.submitting=true;
        let data ={
          referral_code:this.list_referral,
        }
        this.axios
          .post(`reseller/v1/export/list-user-reseller??limit=-1&page=1&start_date=${this.date}&end_date=${this.date2}`, data)
          .then((res) =>{
            this.submitting=false;          
            this.url_ = res.data.data.data.public_url; 
            // console.log('url',this.url_);         
            this.forceFileDownload(this.url_)
          })
          .catch(() => {
            this.submitting=false;
            // this.snackbar.text = res.response.data.message;
            // this.snackbar.state = true; 
          });
      // }            
    },     
  },
};
</script>