<template>
  <v-row>
    <!-- Deskripsi -->
    <v-col cols="12">
      <div class="font-weight-medium mb-2">
        <span> Deskripsi </span>
      </div>
      <vue-editor
        :editorToolbar="customToolbar"
        v-model="data.description"
        class="overflow-y-auto"
        style="max-height: 400px"
        placeholder="Masukan deskripsi"
      ></vue-editor>
    </v-col>
    <!-- LINK -->
    <v-col cols="12">
      <div class="font-weight-medium mb-2">
        <span>Tampilkan Link Google Form</span>
      </div>
      <v-radio-group v-model="data.link_embed.is_showing">
        <v-radio :value="true">
          <span slot="label" class="color--text">Ya</span>
        </v-radio>
        <v-radio :value="false">
          <span slot="label" class="color--text">Tidak</span>
        </v-radio>
      </v-radio-group>
    </v-col>
    <v-col cols="12">
      <div class="font-weight-medium mb-2">
        <span>Link Google Form</span>
      </div>
      <v-text-field placeholder="Masukkan link" v-model="data.link_embed.url"></v-text-field>
    </v-col>
    <!-- Boboboy -->
    <!-- <v-col cols="12">
      <div class="font-weight-medium mb-2">
        <span> Bobot Assesmen </span>
      </div>
      <v-text-field placeholder="Masukkan bobot assesmen"></v-text-field>
    </v-col> -->
  </v-row>
</template>

<script>
import { VueEditor } from "vue2-editor";
import model from "../_mixins/model";

export default {
  components: { VueEditor },
  mixins: [model],
  data: () => ({
    overlay: 0.1,
    overlay_color: "secondary",
  }),
  computed: {
    customToolbar() {
      return [];
    },
  },
  methods: {},
  created() {
    this.data.link_embed = this.data.link_embed ? this.data.link_embed : { is_showing: '', url: '' };
  }
};
</script>
